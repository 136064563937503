export enum BannerType {
  primary = 'primary',
  secondary = 'secondary',
  warning = 'warning',
  error = 'error',
  actionRequired = 'actionRequired',
}

export enum BannerSize {
  regular = 'regular',
  sm = 'sm',
}

export type BannerProps = {
  onSubmit?: () => void;
  type?: BannerType;
  title?: string;
  message: string | React.ReactNode;
  icon?: JSX.Element;
  submitButton?: boolean;
  submitButtonLabel?: string;
  classNames?: string;
  size?: BannerSize;
  isClosable?: boolean;
  iconAlignment?: string;
};

export type StatBannerProps = {
  content: string | React.ReactNode;
  title: string;
  currencies: string[];
};
