import React from 'react';
import { InfoCardProps } from './InfoCard.types';

export const InfoCard = ({ title, description, icon }: InfoCardProps) => {
  return (
    <div className="tw-border-0 tw-rounded-lg tw-bg-neutral-light tw-p-5 tw-mb-5 tw-bg-white">
      <div className="tw-flex tw-flex-col">
        <div className="tw-flex tw-items-center tw-mb-2">
          {icon}
          <div className="tw-text-base tw-font-semibold tw-ml-2">{title}</div>
        </div>
        <div className="tw-text-sm tw-text-neutral-grey-2 tw-ml-16">{description}</div>
      </div>
    </div>
  );
};
