import { gql } from '@apollo/client';

export const GET_ME = gql`
  query Me {
    me {
      ... on User {
        id
        activeAccountId
        email
        embossedName
        firstName
        lastName
        mobilePhoneNumber
        mobilePhoneNumberVerified
        smsMfaEnabled
        totpMfaEnabled
        suggestedCreditCardDisplayNames
        superAdmin
        roles
        contactId
        internalContact {
          id
          role
          permissions
          status
        }
        permissions {
          manageBankAccounts
          manageExternalAccounts
          manageLinesOfCredit
          managePayments
        }
        settings {
          emailNotification
          smsNotification
        }
        accounts {
          id
          name
        }
        account {
          features
          hasCompleteExternalUsdAccount
          entityType
          onboardingStatus
          name
          displayName
          entityType
          welcomeModalSeen
          newSolidAccountNumberSeen
          physicalCardCount
          productState {
            preFunded
            creditCard
            capitalProducts
            account
          }
          reconnectExternalAccount
          creditCards {
            id
          }
          bankAccount {
            reconnectionRequired
            flinksConnectionId
          }
          lineOfCredit {
            id
            creditLimit {
              currency
            }
          }
          creditCards {
            id
          }
          kycAssessment {
            status
            reasons
          }
          creditAssessment {
            status
          }
          externalAccountsConnected
          payees {
            id
          }
          subscription {
            paid
            name
            endDate
            nextStartDate
            price
          }
          maxPhysicalCards
          maxVirtualCards
          loopDomainEmail
          settings {
            numApprovers
          }
          hasCadCoreIntegration
        }
        agreements {
          status
          documentType
          productType
          signedAt
        }
        creditAgreementSigned
      }
      ... on PayorUser {
        id
        contactId
        email
        firstName
        lastName
        legalBusinessName
        preAuthorizationForms {
          bankAccount {
            id
            currency
          }
          version
        }
      }
    }
  }
`;

export const GET_CRA_BUSINESS_NUMBER = gql`
  query CraBusinessNumber {
    me {
      ... on User {
        account {
          craBusinessNumber
          creditApplication {
            id
          }
        }
      }
    }
  }
`;

export const GET_ACCOUNT_INDUSTRIES = gql`
  query AccountIndustries {
    me {
      ... on User {
        account {
          mainIndustry
          industry
        }
      }
    }
  }
`;

export const GET_NAICS_INFO = gql`
  query NaicsInfo($mainIndustry: MainIndustryEnum!, $industry: IndustryEnum!) {
    naicsInfo(mainIndustry: $mainIndustry, industry: $industry) {
      code
      description
    }
  }
`;

export const SIGN_UP = gql`
  mutation SignUp(
    $country: CountryEnum!
    $craBusinessNumber: String
    $email: String!
    $firstName: String!
    $lastName: String!
    $legalBusinessName: String!
    $password: String!
    $subscribed: Boolean!
    $utm: String
    $monthlyOnlineRevenue: MonthlyOnlineRevenueEnum!
  ) {
    signUp(
      country: $country
      craBusinessNumber: $craBusinessNumber
      email: $email
      firstName: $firstName
      lastName: $lastName
      legalBusinessName: $legalBusinessName
      password: $password
      subscribed: $subscribed
      monthlyOnlineRevenue: $monthlyOnlineRevenue
      utm: $utm
    )
  }
`;

export const USER_INVITATION = gql`
  query UserInvitation($sgid: String!) {
    userInvitation(signedId: $sgid) {
      id
      firstName
      lastName
      email
      account {
        name
      }
    }
  }
`;

export const ACCEPT_USER_INVITE = gql`
  mutation AcceptUserInvite(
    $signedId: String!
    $address: AddressInput!
    $birthdate: ISO8601Date!
    $firstName: String!
    $lastName: String!
    $livedInTheSameAddressRecently: Boolean!
    $mobilePhoneNumber: String!
    $occupation: String!
    $otherAddress: AddressInput
    $password: String!
    $phoneNumber: String
    $socialInsuranceNumber: String
  ) {
    acceptUserInvite(
      signedId: $signedId
      address: $address
      birthdate: $birthdate
      firstName: $firstName
      lastName: $lastName
      livedInTheSameAddressRecently: $livedInTheSameAddressRecently
      mobilePhoneNumber: $mobilePhoneNumber
      occupation: $occupation
      otherAddress: $otherAddress
      password: $password
      phoneNumber: $phoneNumber
      socialInsuranceNumber: $socialInsuranceNumber
    )
  }
`;

export const GUARANTOR_CONTACT = gql`
  query GuarantorContact($sgid: String!) {
    guarantorContact(signedId: $sgid) {
      id
      firstName
      lastName
      email
      livedInTheSameAddressRecently
      birthdate
      occupation
      address {
        city
        country
        countrySubdivision
        postalCode
        street
        unitNumber
      }
      otherAddress {
        city
        country
        postalCode
        countrySubdivision
        street
        unitNumber
      }
      account {
        name
        phone
      }
      contactId
      personaTemplateId
      personaEnvironmentId
      thirdPartyVerificationComplete
      creditCheckAuthorizationProvided
    }
  }
`;

export const CONSENT_TO_CREDIT_CHECK = gql`
  mutation ConsentToCreditCheck(
    $signedId: String!
    $email: String!
    $address: AddressInput!
    $birthdate: ISO8601Date!
    $firstName: String!
    $lastName: String!
    $livedInTheSameAddressRecently: Boolean!
    $occupation: String!
    $otherAddress: AddressInput
    $phoneNumber: String!
    $socialInsuranceNumber: String
  ) {
    consentToCreditCheck(
      signedId: $signedId
      address: $address
      email: $email
      birthdate: $birthdate
      firstName: $firstName
      lastName: $lastName
      livedInTheSameAddressRecently: $livedInTheSameAddressRecently
      occupation: $occupation
      otherAddress: $otherAddress
      phoneNumber: $phoneNumber
      socialInsuranceNumber: $socialInsuranceNumber
    )
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const PASSWORD_RESET_EMAIL = gql`
  query PasswordResetEmail($token: String!) {
    passwordResetEmail(token: $token)
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($token: String!, $password: String!, $passwordConfirmation: String!) {
    updatePassword(token: $token, password: $password, passwordConfirmation: $passwordConfirmation)
  }
`;

export const ACCEPT_TEAM_INVITATION = gql`
  mutation AcceptTeamInvitation(
    $signedId: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
  ) {
    acceptTeamInvitation(
      signedId: $signedId
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
    )
  }
`;

export const SET_WELCOME_MODAL_SEEN = gql`
  mutation {
    setWelcomeModalSeen
  }
`;

export const SET_ACCOUNT_SESSION = gql`
  mutation SetAccountSession($accountId: ID!) {
    setAccountSession(accountId: $accountId)
  }
`;

export const PAYOR_SIGN_UP = gql`
  mutation PayorSignUp(
    $signedId: String!
    $firstName: String!
    $lastName: String!
    $legalBusinessName: String!
    $email: String!
    $password: String!
  ) {
    payorSignUp(
      signedId: $signedId
      firstName: $firstName
      lastName: $lastName
      legalBusinessName: $legalBusinessName
      email: $email
      password: $password
    ) {
      id
    }
  }
`;

export const GET_BUSINESS_ADDRESS = gql`
  query BusinessAddress {
    me {
      ... on User {
        account {
          address {
            street
            unitNumber
            city
            countrySubdivision
            postalCode
            country
          }
        }
      }
    }
  }
`;

export const AUTH_EMAIL_VERIFICATION = gql`
  mutation AuthEmailVerification($sgid: String!) {
    authEmailVerification(sgid: $sgid)
  }
`;

export const SET_USER_PERMISSIONS = gql`
  mutation SetUserPermissions($userId: ID, $teamInvitationId: ID, $contactId: ID, $permissions: [String!]) {
    setUserPermissions(
      userId: $userId
      teamInvitationId: $teamInvitationId
      contactId: $contactId
      permissions: $permissions
    )
  }
`;
