import React from 'react';

import { Modal } from 'components/UI';
import { ActivateCardModalType } from './ActivateCardModal.types';
import useActivateCardModal from './hooks/useActivateCardModal';
import ExampleCardBack from 'components/svg/ExampleCardBack';
import { SubmitButton } from 'components/FormFields/v2';
import { FormProvider } from 'react-hook-form';
import { BasisTheoryCvcInputField } from './components';

const ActivateCardModal = ({ show, onClose, closeOnActivate }: ActivateCardModalType) => {
  const { lastFourDigits, onSubmit, form, handleSubmit, loading, bt, securityCodeRef } = useActivateCardModal({
    onClose: closeOnActivate || onClose,
  });
  return (
    <Modal show={show} onClose={onClose} title="Activate Card">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-flex tw-flex-col tw-px-12 tw-pt-8 tw-p-4 tw-border-t tw-border-b tw-border-neutral-grey-4">
            <div className="tw-flex tw-mb-4 tw-justify-center">
              <ExampleCardBack />
            </div>
            <strong className="tw-mt-4 tw-mb-6">
              To activate your physical card, enter the 3-digit security code found on the back of your card
            </strong>
            <div>
              <div className="tw-flex tw-items-center tw-justify-between">
                <p className="tw-mr-4">Last 4 digits</p>
                <div className="tw-flex tw-items-center">
                  <p>∗∗∗∗ - ∗∗∗∗ - ∗∗∗∗ - {lastFourDigits}</p>
                </div>
              </div>
              <div className="tw-flex tw-items-center tw-justify-between tw-my-4">
                <p className="tw-mr-4">CVC2</p>
                <BasisTheoryCvcInputField securityCodeRef={securityCodeRef} bt={bt} />
              </div>
            </div>
          </div>
          <div className="tw-flex tw-justify-end tw-px-8 tw-pt-8 tw-p-4 tw-border-t tw-border-t tw-border-neutral-grey-4">
            <SubmitButton disabled={loading}>{loading ? 'Submitting...' : 'Activate'}</SubmitButton>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default ActivateCardModal;
