import React from 'react';
import SettingsLayout from 'components/settings/SettingsLayout';
import ConfigureEmailSection from './components/ConfigureEmailSection';
import { Tab } from '@headlessui/react';
import { IoReceiptOutline } from 'react-icons/io5';

const ExpenseManagement = () => {
  const tabs = [
    {
      label: 'Receipt Settings',
      icon: <IoReceiptOutline />,
    },
  ];
  return (
    <div className="tw-flex tw-flex-col tw-justify-around tw-w-full">
      <SettingsLayout>
        <div className="tw-mb-16 tw-px-4 tw-py-8">
          <h5 className="tw-pb-4">Expense Management</h5>
          <Tab.Group>
            <Tab.List className="tw-flex tw-justify-between tw-items-center tw-my-4">
              {tabs.map((tab) => (
                <Tab
                  key={tab.label}
                  className={({ selected }) =>
                    `tw-bg-neutral-grey-5 tw-py-2 tw-px-6 tw-border-2 ${
                      selected
                        ? 'tw-border-primary-light-green tw-bg-primary-light-green tw-text-primary-dark-green hover:tw-text-primary-dark-green'
                        : 'tw-border-neutral-grey-4'
                    } tw-rounded-md tw-inline-flex tw-items-center tw-mr-4 tw-gap-x-2`
                  }
                >
                  {tab.icon}
                  {tab.label}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <ConfigureEmailSection />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </SettingsLayout>
    </div>
  );
};
export default ExpenseManagement;
