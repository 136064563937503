import React from 'react';

import { FormFooter } from 'components/UI';
import { Loaders } from 'components/cards/Loader';
import { TransactionDetails, PDAModal } from './components';
import { useReview } from './hooks';
import { ReviewProps } from './Review.types';

import styles from './Review.module.scss';

const Review = ({ onNextStep, onPrevStep, onComplete }: ReviewProps) => {
  const {
    transactions,
    isLoading,
    handleSubmitReviewStep,
    handleSubmitPADAgreement,
    showPDAModal,
    closePDAModal,
    isPreDepositAuthorizationRequired,
    firstFromBankAccountsWithoutPAD,
    hideTransactionLabels,
    padAgreementVersion,
  } = useReview({
    onNextStep,
    onPrevStep,
    onComplete,
  });

  if (isLoading) return <Loaders.Light />;

  return (
    <>
      <div className="tw-text-neutral-grey-2 tw-text-sm tw-mb-8">Please confirm your transfer details.</div>
      <ul className={styles.transactions}>
        {transactions?.map((transaction) => (
          <li key={transaction.originalAmount.currency} className={styles.transactions__item}>
            {!hideTransactionLabels && (
              <div className="tw-font-bold tw-text-neutral-grey-1 tw-mb-6">
                {transaction.originalAmount.currency} Card Payment
              </div>
            )}

            <TransactionDetails transaction={transaction} />
          </li>
        ))}
      </ul>
      <FormFooter
        submitButtonLabel="Submit Transfer"
        cancelButtonLabel="Edit"
        onSubmit={handleSubmitReviewStep}
        onCancel={onPrevStep}
      />
      {isPreDepositAuthorizationRequired && firstFromBankAccountsWithoutPAD && (
        <PDAModal
          show={showPDAModal}
          onClose={closePDAModal}
          onSubmit={handleSubmitPADAgreement}
          bankAccountWithoutPDA={firstFromBankAccountsWithoutPAD}
          version={padAgreementVersion}
        />
      )}
    </>
  );
};

export default Review;
