import { GAMIFICATION_CODES } from './constants';
import { ProductContent } from './ProductContent.types';
import firstDeposit from 'images/firstDeposit.png';
import firstPurchase from 'images/firstPurchase.png';
import firstConversion from 'images/firstConversion.png';
import referAndEarn from 'images/referAndEarn.png';

export const getCards = ({ accountCompletedEventsCodes }: { accountCompletedEventsCodes: string[] }) => {
  const content: ProductContent[] = [];

  const dynamicContent: ProductContent[] = [
    {
      icon: firstPurchase,
      code: 'first_card_purchase',
      cardColor: '#C4F6C6',
      title: 'Make Your First Purchase',
      subtitle: 'Earn 500 bonus points',
      numberOfPoints: 500,
    },
    {
      icon: firstDeposit,
      code: 'first_account_deposit',
      cardColor: '#D5AFFC',
      textColor: '#383B3E',
      title: 'Receive Your First Deposit',
      subtitle: 'Earn 750 bonus points',
      numberOfPoints: 750,
    },
    {
      icon: firstConversion,
      code: 'first_account_conversion',
      cardColor: '#89C4B0',
      textColor: '#383B3E',
      title: 'Make Your First Conversion',
      subtitle: 'Earn 1,000 bonus points',
      numberOfPoints: 1000,
    },
  ];

  const staticContent: ProductContent[] = [
    {
      icon: referAndEarn,
      cardColor: '#82E0FE',
      title: 'Refer A Business To Loop',
      subtitle: 'Earn 10,000 bonus points',
      numberOfPoints: 10000,
    },
  ];

  Object.values(GAMIFICATION_CODES).forEach((code) => {
    const product = dynamicContent.find((element) => element.code === code);
    if (!accountCompletedEventsCodes.includes(code) && product) {
      content.push(product);
    }
  });

  content.push(...staticContent);

  return content;
};
