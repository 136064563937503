import React, { useState, useContext } from 'react';
import { Collapse } from 'react-collapse';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';

import { formatDateTime } from 'utility/date';
import fileDownload from 'utility/fileDownload';
import { Loaders } from 'components/cards/Loader';
import Modals from 'components/settings/documents/Modals';
import SettingsLayout from 'containers/settings/SettingsLayout';
import { documentCategories, SCOPE } from 'constants/index';
import Add from 'components/svg/Add';
import ChevronUp from 'components/svg/ChevronUp';
import ChevronDown from 'components/svg/ChevronDown';
import DocumentTextOutline from 'components/svg/DocumentTextOutline';
import DocumentAttachOutline from 'components/svg/DocumentAttachOutline';
import SuccessCheckmark from 'components/svg/SuccessCheckmark';
import { NotificationAlertContext } from 'context/NotificationAlert';
import Button from 'components/Button';
import PermissionChecker from 'components/PermissionChecker';
import Notification from 'components/Notification';
import { GET_DOCUMENT_DOWNLOAD_URL } from 'graphql/document';

const MyDocumentsSection = ({ documents, reload, setNotification }) => {
  const [modalCategory, setModalCategory] = React.useState(0);
  const [showUploaderModal, setShowUploaderModal] = React.useState(false);
  const onHideUploaderModal = () => {
    setShowUploaderModal(false);
  };
  const onShowUploaderModal = (category) => {
    setModalCategory(category);
    setShowUploaderModal(true);
  };
  const onSuccessfullyUpload = () => {
    reload();
    setNotification(
      <Notification
        Icon={SuccessCheckmark}
        message="New document successfully uploaded (verification may take up to 24h)"
      />
    );
    onHideUploaderModal();
  };

  const INITIAL_DOCUMENT = { id: '', name: '' };

  const AttachedDocumentSection = ({ documents }) => {
    const [selectedDocument, setSelectedDocument] = useState(INITIAL_DOCUMENT);
    const [isDownloading, setIsDownloading] = useState(false);

    const onGetDownloadUrl = async (data) => {
      const downloadUrl = get(data, 'documentsDownload.downloadUrl');
      try {
        await fileDownload(downloadUrl, selectedDocument.name, {
          onComplete: () => {
            setIsDownloading(false);
            setSelectedDocument(INITIAL_DOCUMENT);
          },
        });
      } catch (error) {
        console.error(error);
      }
    };

    const [getUrl] = useLazyQuery(GET_DOCUMENT_DOWNLOAD_URL, {
      onCompleted: onGetDownloadUrl,
    });

    const handleDocumentClick = ({ documentId, documentName }) => {
      setSelectedDocument({ id: documentId, name: documentName });
      setIsDownloading(true);
      getUrl({ variables: { documentId } });
    };

    return (
      <div>
        {documents.map((document) => {
          return (
            <div key={document.name}>
              <div className="tw-flex tw-flex-row tw-justify-between">
                <div className="tw-flex tw-flex-row tw-justify-start tw-w-1/3 tw-text-primary-dark-green">
                  <div className="tw-mr-4">
                    <DocumentAttachOutline />
                  </div>
                  <button
                    onClick={() => handleDocumentClick({ documentId: document.id, documentName: document.name })}
                    className="tw-flex tw-items-center"
                  >
                    {isDownloading && selectedDocument.id === document.id ? <Loaders.Spinner /> : document.name}
                  </button>
                </div>
                <small className="tw-w-1/3 tw-text-neutral-grey-2">
                  {`Uploaded at ${formatDateTime(document.createdAt)}`}
                </small>
                <div className="tw-w-1/3">
                  {document.verified && (
                    <small className="tw-bg-secondary-light-green tw-rounded tw-text-semantic-success tw-flex tw-flex-shrink-1 tw-justify-center tw-mx-16">
                      Verified
                    </small>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const DocumentCategorySection = ({ categoryName, documents }) => {
    var [expanded, setExpanded] = React.useState(false);
    const toggle = () => {
      setExpanded(!expanded);
    };

    const name = documentCategories.find((c) => c.value === categoryName)?.name || categoryName;

    return (
      <div className="tw-border-2 tw-border-rounded tw-border-neutral-grey-4 tw-flex tw-flex-col">
        <div className="tw-items-center tw-px-4 tw-pt-4 tw-flex tw-flex-row tw-justify-between tw-mb-4">
          <div className="tw-items-center tw-flex tw-flex-row tw-justify-start">
            <div className="tw-mr-4">
              <DocumentTextOutline />
            </div>
            <div>{name}</div>
          </div>
          <Button className="tw-border-2 tw-rounded-lg tw-border-neutral-grey-4 tw-p-1" onClick={() => toggle()}>
            {expanded ? <ChevronUp /> : <ChevronDown />}
          </Button>
        </div>
        <Collapse isOpened={expanded}>
          <div className="tw-px-4">
            <AttachedDocumentSection documents={documents} />
          </div>
          <PermissionChecker scope={SCOPE.manageDocuments}>
            <>
              <hr className="tw-bg-neutral-grey-4 tw-my-2 tw-w-full" />
              <div className="tw-p-2 tw-flex tw-justify-end tw-text-primary-dark-green">
                <Button
                  className="tw-flex tw-flex-row tw-justify-between tw-items-center"
                  onClick={() => onShowUploaderModal(categoryName)}
                >
                  <Add size="16" />
                  <p className="tw-mx-2">Add File</p>
                </Button>
              </div>
            </>
          </PermissionChecker>
        </Collapse>
      </div>
    );
  };

  const DocumentCategoriesSection = ({ documents }) => {
    const groupBy = (xs, key) =>
      xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});

    const groupedDocuments = documents && documents.length > 0 ? groupBy(documents, 'category') : {};
    return (
      <>
        <div>
          {Object.keys(groupedDocuments).map((category) => {
            return (
              <div key={category} className="tw-my-4">
                <DocumentCategorySection categoryName={category} documents={groupedDocuments[category]} />
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="tw-flex tw-flex-col">
        <div className="tw-pb-8 tw-flex tw-flex-row tw-justify-between">
          <h5 className="tw-w-3/6">{`My Documents (${documents ? documents.length : ''})`}</h5>
          <PermissionChecker scope={SCOPE.manageDocuments}>
            <div className="tw-flex tw-justify-end tw-w-2/6">
              <Button primary className="tw-min-w-max" onClick={() => onShowUploaderModal(0)}>
                <Add className="tw-mr-2" />
                <p className="tw-text-neutral-light">Upload New Document</p>
              </Button>
            </div>
          </PermissionChecker>
        </div>
        <div className="tw-p-2">
          <DocumentCategoriesSection documents={documents} />
        </div>
      </div>
      <Modals.Upload
        show={showUploaderModal}
        onClose={onHideUploaderModal}
        categoryValue={modalCategory}
        reload={reload}
        onSuccessfullyUpload={onSuccessfullyUpload}
      />
    </div>
  );
};

const Content = ({ documents, reload, setNotification }) => {
  return (
    <div className="tw-w-90">
      <div className="tw-container-md tw-flex tw-flex-row tw-justify-start tw-mb-5 tw-px-4 tw-py-8">
        <div className="tw-container">
          <MyDocumentsSection documents={documents} reload={reload} setNotification={setNotification} />
        </div>
      </div>
    </div>
  );
};

const Documents = (props) => {
  const { documents, loading, reload } = props;
  const { setNotification, notification } = useContext(NotificationAlertContext);

  return (
    <div className="tw-container-md tw-flex tw-flex-col tw-justify-around tw-w-full">
      <SettingsLayout notification={notification}>
        {loading ? (
          <Loaders.Light />
        ) : (
          <Content documents={documents} reload={reload} setNotification={setNotification} />
        )}
      </SettingsLayout>
    </div>
  );
};

export default Documents;
