import React from 'react';
import { ReviewReimbursementRequestDetailsProps } from './ReviewReimbursementRequestDetails.types';
import { RequestDetails } from './components';
import { formatDateTime } from 'utility/date';
import { formatMoneyV2 } from 'utility/currency';
import { useReviewReimbursementRequestDetails } from './hooks';
import ModalFooter from '../../ModalFooter';

const ReviewReimbursementRequestDetails = ({ onPrevStep, onNextStep }: ReviewReimbursementRequestDetailsProps) => {
  const {
    merchantName,
    description,
    amountRequested,
    originalAmount,
    taxAmount,
    transactionDate,
    showAmountInCAD,
    showTaxAmount,
    handleSubmit,
    isLoading,
  } = useReviewReimbursementRequestDetails({ onPrevStep, onNextStep }) || {};

  if (isLoading)
    return (
      <div className="tw-text-center tw-p-20">
        <div className="spinner-border tw-min-h-8 tw-min-w-8 m-5" role="status"></div>
      </div>
    );
  return (
    <>
      <div className="tw-px-8 tw-pt-8 tw-mb-8">
        <div className="tw-text-sm tw-text-neutral-grey-2">Please confirm reimbursement request details</div>
        <div className="tw-flex tw-gap-8 tw-py-8 tw-pb-4">
          <div className="tw-flex tw-flex-col tw-space-y-8 tw-w-1/2">
            <RequestDetails label="Description" value={description} />
            <RequestDetails label="Merchant Name" value={merchantName} />
            <RequestDetails label="Expense Date" value={formatDateTime(transactionDate)} />
          </div>
          <div className="tw-flex tw-flex-col tw-space-y-8 tw-w-1/2">
            <RequestDetails
              label="Amount Requested"
              value={`${formatMoneyV2(originalAmount)} ${originalAmount?.currency} `}
            />
            {showTaxAmount && (
              <RequestDetails label="Tax Amount" value={`${formatMoneyV2(taxAmount)} ${taxAmount?.currency}`} />
            )}
            {showAmountInCAD && <RequestDetails label="Amount in CAD" value={`${formatMoneyV2(amountRequested)}`} />}
          </div>
        </div>
      </div>
      <ModalFooter
        onCancel={onPrevStep}
        onSubmit={handleSubmit}
        cancelButtonLabel="Edit details"
        submitButtonLabel="Confirm"
        loading={isLoading}
      />
    </>
  );
};

export default ReviewReimbursementRequestDetails;
