import React from 'react';

import { Modal } from 'components/UI';
import PADAgreementCA from 'components/payments/PADAgreementCA';
import { PDAModalProps } from './PDAModal.types';

const PDAModal = ({ show, onClose, onSubmit, bankAccountWithoutPDA, version }: PDAModalProps) => (
  <Modal show={show} onClose={onClose}>
    <PADAgreementCA
      onNextStep={onSubmit}
      loading={false}
      bankAccountWithoutPDA={bankAccountWithoutPDA}
      version={version}
    />
  </Modal>
);

export default PDAModal;
