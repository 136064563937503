import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SolidMigrationModalProps } from '../SolidMigrationModal.types';
import { loadFromLocalStorage, saveToLocalStorage } from 'utility/localStorage';

const useSolidMigrationModal = ({ shouldShowSolidModal }: SolidMigrationModalProps) => {
  const history = useHistory();

  const showModal = loadFromLocalStorage<boolean>('solidMigrationModalAlreadyShown', true)
    ? false
    : shouldShowSolidModal;

  const [show, setShow] = useState(showModal);
  const onNavigateToBankAccounts = () => {
    onClose();
    history.push('/dashboard/accounts');
  };

  const onClose = () => {
    setShow(false);
    saveToLocalStorage('solidMigrationModalAlreadyShown', true);
  };

  return {
    onNavigateToBankAccounts,
    show,
    onClose,
  };
};

export default useSolidMigrationModal;
