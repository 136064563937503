import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useToggle } from 'hooks';
import { useHistory } from 'react-router-dom';

const useCardAction = ({ creditCardId }: { creditCardId: string | null }) => {
  const history = useHistory();
  const { isOpen: showActivationModal, toggle: toggleActivationModal } = useToggle();

  useEffect(() => {
    if (showActivationModal && creditCardId === null) {
      toast.error('Unable to activate card. Please contact support.');
    }
  }, [showActivationModal, creditCardId]);

  const handleCloseOnCardActivated = () => {
    toggleActivationModal();
    history.push(`/dashboard/cards/list/${creditCardId}`);
  };

  return {
    showActivationModal,
    toggleActivationModal,
    handleCloseOnCardActivated,
  };
};

export default useCardAction;
