import { useEffect, useState, useCallback, useRef } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import {
  CREATE_CORE_EXTERNAL_ACCOUNT_AGREEMENT_SIGNING_ENVELOPE,
  CORE_EXTERNAL_ACCOUNT_AGREEMENT_SIGNING_URL,
  CORE_EXTERNAL_ACCOUNT_AGREEMENT_STATUS,
} from 'graphql/wallets';
import { DocuSignEvent } from 'components/DocuSign/DocuSign.types';
import {
  CoreExternalAccountAgreement,
  CADAgreementDocumentType,
  USDAgreementDocumentType,
  AgreementStatus,
} from 'types/coreExternalAccount';
import { AGREEMENT_STATE_REFETCHING_INTERVAL, AGREEMENT_STATE_REFETCHING_LIMIT } from '../constants';

const useExternalAccountAgreementState = ({
  externalWalletAccountId,
  agreements,
  selectedDocumentType,
}: {
  externalWalletAccountId?: string;
  agreements: CoreExternalAccountAgreement[];
  selectedDocumentType: CADAgreementDocumentType | USDAgreementDocumentType | undefined;
}) => {
  const [agreement, setAgreement] = useState<CoreExternalAccountAgreement>();
  const [signingUrl, setSigningUrl] = useState<string>();
  const [isSigningFailed, setIsSigningFailed] = useState(false);
  const [isSigningCompleted, setIsSigningCompleted] = useState(false);

  const [
    createCoreExternalAccountAgreementSigningEnvelope,
    { error: createAgreementEnvelopeError, loading: createAgreementEnvelopeLoading },
  ] = useMutation<{
    createCoreExternalAccountAgreementSigningEnvelope: string | null;
  }>(CREATE_CORE_EXTERNAL_ACCOUNT_AGREEMENT_SIGNING_ENVELOPE, {
    onCompleted: (data) => {
      const envelopeId = data.createCoreExternalAccountAgreementSigningEnvelope;
      if (envelopeId && agreement) setAgreement({ ...agreement, envelopeId });
    },
  });

  const [
    getCoreExternalAccountAgreementSigningUrl,
    { error: agreementSigningUrlError, loading: agreementSigningUrlLoading },
  ] = useLazyQuery<{
    coreExternalAccountAgreementSigningUrl: string | null;
  }>(CORE_EXTERNAL_ACCOUNT_AGREEMENT_SIGNING_URL, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const signingUrl = data.coreExternalAccountAgreementSigningUrl;
      if (signingUrl) setSigningUrl(signingUrl);
    },
  });

  const [getCoreExternalAccountAgreementStatus, { error: agreementStatusError, loading: agreementStatusLoading }] =
    useLazyQuery<{
      coreExternalAccountAgreementStatus: AgreementStatus | null;
    }>(CORE_EXTERNAL_ACCOUNT_AGREEMENT_STATUS, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const status = data.coreExternalAccountAgreementStatus;
        setAgreement((prevState) => prevState && { ...prevState, status });
      },
    });

  const isAgreementSigned = agreement?.status === AgreementStatus.signed;

  const pullingIntervalId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!agreements) return;

    const agreement = agreements.find((agreement) => agreement.documentType === selectedDocumentType);
    setAgreement(agreement);
  }, [agreements, selectedDocumentType]);

  useEffect(() => {
    if (!externalWalletAccountId || !agreement?.id || isAgreementSigned || agreement?.envelopeId) return;

    createCoreExternalAccountAgreementSigningEnvelope({
      variables: {
        externalWalletAccountId,
        agreementId: agreement.id,
      },
    });
  }, [externalWalletAccountId, isAgreementSigned, agreement?.id, agreement?.envelopeId]);

  useEffect(() => {
    if (!externalWalletAccountId || !agreement?.id || isAgreementSigned || !agreement?.envelopeId) return;

    getCoreExternalAccountAgreementSigningUrl({
      variables: {
        externalWalletAccountId,
        agreementId: agreement.id,
      },
    });
  }, [externalWalletAccountId, isAgreementSigned, agreement?.id, agreement?.envelopeId]);

  const handleIframeMessage = useCallback((e: MessageEvent) => {
    const originUrl = window.location.origin;
    if (!e?.origin?.startsWith(originUrl)) return;

    const docuSignEvent = e.data?.docuSignEvent;

    if (!docuSignEvent) return;

    if (docuSignEvent === DocuSignEvent.signing_complete) {
      setIsSigningFailed(false);
      setIsSigningCompleted(true);
    } else {
      setIsSigningFailed(true);
      setIsSigningCompleted(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage);

    return () => window.removeEventListener('message', handleIframeMessage);
  }, []);

  useEffect(() => {
    if (!externalWalletAccountId || !agreement?.id || !isSigningCompleted || isAgreementSigned) return;

    pullingIntervalId.current = setInterval(async () => {
      await getCoreExternalAccountAgreementStatus({
        variables: {
          externalWalletAccountId,
          agreementId: agreement.id,
        },
      });
    }, AGREEMENT_STATE_REFETCHING_INTERVAL);

    // Stop the interval after a certain time
    const timeoutId = setTimeout(() => {
      if (pullingIntervalId.current) {
        clearInterval(pullingIntervalId.current);
      }
    }, AGREEMENT_STATE_REFETCHING_LIMIT);

    return () => {
      if (pullingIntervalId.current) {
        clearInterval(pullingIntervalId.current);
      }
      clearTimeout(timeoutId);
    };
  }, [externalWalletAccountId, agreement?.id, isSigningCompleted, isAgreementSigned]);

  useEffect(() => {
    if (isAgreementSigned && pullingIntervalId.current) {
      clearInterval(pullingIntervalId.current);
    }
  }, [isAgreementSigned]);

  return {
    signingUrl,
    isAgreementSigned,
    isSigningFailed,
    error: createAgreementEnvelopeError || agreementSigningUrlError || agreementStatusError,
    loading: createAgreementEnvelopeLoading || agreementSigningUrlLoading || agreementStatusLoading,
  };
};

export default useExternalAccountAgreementState;
