/**
 * whilst generating new start and end timestamps, please use
 * new Date('2024 09 01 00:00:00 am').getTime()
 */

export const StatutoryHolidays = [
  {
    title: 'Holiday Delays - Labour Day',
    message:
      'Please note that payments may take longer to clear due to the upcoming holiday. Thank you for your understanding.',
    currencies: ['USD', 'CAD'],
    startDateTimestamp: 1724904000000, // 2024 08 29 00:00:00
    endDateTimestamp: 1725336000000, // 2024 09 03 00:00:00
  },
];
