import React from 'react';

import LockAndStar from 'components/svg/LockAndStar';
import InfoBlock from '../InfoBlock';

const BankAccountBanner = () => {
  return (
    <div className="tw-mb-8">
      <InfoBlock
        content={
          'Connecting your business bank account to Loop allows you to easily and quickly transfer funds between Loop and accounts you hold with other banks.'
        }
        icon={<LockAndStar className={'tw-w-full tw-h-full tw-relative'} />}
      />
    </div>
  );
};

export default BankAccountBanner;
