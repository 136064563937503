import config from '../config';

export const creditApplicationStatus = {
  NOT_STARTED: 'NOT_STARTED',
  DRAFT: 'DRAFT',
  COMPLETED: 'COMPLETED',
  REVIEWED: 'REVIEWED',
};

export const pendingTask = {
  bank: 'CONNECT_BANK',
  documents: 'UPDATE_DOCUMENTS',
  card: 'CREATE_CARD',
};

export const transactionTypes = {
  WITHDRAWAL: 'Withdrawal',
  WITHDRAWAL_FEE: 'Withdrawal Fee',
  MONTHLY_PAYMENT: 'Monthly Payment',
  INTEREST_CHARGE: 'Interest Charge',
  MONTHLY_FEE: 'Monthly Fee',
  CANCELLATION: 'Cancellation',
  BALANCE_REPAYMENT: 'Balance Repayment',
  CORRECTION: 'Correction',
  CREDIT: 'Credit',
  MONTHLY_PAYMENT_NSF: 'Monthly Payment Reversal - NSF',
  BALANCE_REPAYMENT_NSF: 'One-Time Payment Reversal - NSF',
  NSF_FEE: 'NSF Fee',
  ROLLBACK: 'Rollback',
  LATE_PAYMENT_FEE: 'Late Payment Fee',
  WIRE_FEE: 'Wire Fee',
  FEE: 'Fee',
  DEPOSIT: 'Deposit',
};

export const transactionStatus = {
  PENDING: 'Pending',
  POSTED: 'Complete',
  DENIED: 'Denied',
  PROCESSING: 'Processing',
  FAILED: 'Failed',
  NSF: 'NSF (Non-sufficient Funds)',
  FLAGGED_FOR_REVIEW: 'Flagged for Review',
};

export const documentCategories = [
  { name: 'Bank Statement for the Business', value: 'BANK_STATEMENTS', additionalValue: null, key: 'BANK_STATEMENTS' },
  { name: 'Business License or Permit', value: 'BUSINESS_PERMITS', additionalValue: null, key: 'BUSINESS_PERMITS' },
  { name: 'Corporate Ownership', value: 'CORPORATE_OWNERSHIP', additionalValue: null, key: 'CORPORATE_OWNERSHIP' },
  {
    name: 'CRA Notice of Assessment For Most Recent Tax Year',
    value: 'CRA_STATEMENT_NOAS',
    additionalValue: null,
    key: 'CRA_STATEMENT_NOAS',
  },
  {
    name: 'Financial Statements',
    value: 'FINANCIAL_STATEMENTS',
    additionalValue: null,
    key: 'FINANCIAL_STATEMENTS',
  },
  { name: 'Marketing Data', value: 'MARKETING_DOCUMENTS', additionalValue: null, key: 'MARKETING_DOCUMENTS' },
  { name: 'Other Statements', value: 'OTHER_STATEMENTS', additionalValue: null, key: 'OTHER_STATEMENTS' },
  { name: 'Other Data', value: 'OTHERS', additionalValue: null, key: 'OTHERS' },
  {
    name: 'Current Inventory Listing',
    value: 'CURRENT_INVENTORY_LISTINGS',
    additionalValue: null,
    key: 'CURRENT_INVENTORY_LISTINGS',
  },
  { name: 'Purchase Orders', value: 'PURCHASE_ORDERS', additionalValue: null, key: 'PURCHASE_ORDERS' },
  { name: 'Account Receivable', value: 'ACCOUNT_RECEIVABLES', additionalValue: null, key: 'ACCOUNT_RECEIVABLES' },
  { name: 'T1 Tax Return', value: 'T1_SLIPS', additionalValue: null, key: 'T1_SLIPS' },
  {
    name: "Photo Identification (eg. Passport or Driver's License)",
    value: 'PHOTO_IDS',
    additionalValue: null,
    contactId: null,
    key: 'PHOTO_IDS',
  },
  {
    name: 'Utility Bill or Business Bill/Invoice',
    value: 'OTHER_INVOICES',
    additionalValue: null,
    key: 'OTHER_INVOICES',
  },
];

export const supplierTypes = [
  { name: 'Company', value: 'company' },
  { name: 'Individual', value: 'individual' },
];

export const creditCardTypes = [
  { name: 'Visa', value: 'visa', startingDigits: ['4'] },
  { name: 'Mastercard', value: 'mastercard', startingDigits: ['2', '5'] },
  { name: 'American Express', value: 'amex', startingDigits: ['3'] },
  { name: 'other', value: 'other', startingDigits: null },
];

export const craProgramAccountTypes = [
  { name: 'Goods and Services Tax/Harmonized Sales Tax (RT)', value: 'rt' },
  { name: 'Payroll (RP)', value: 'rp' },
  { name: 'Corporate Income Tax (RC)', value: 'rc' },
  { name: 'Import/Export (RM)', value: 'rm' },
  { name: 'Information Return (RZ)', value: 'rz' },
];

export const countries = [
  { name: 'Canada', value: 'CA' },
  { name: 'United States of America', value: 'US' },
  { name: 'China', value: 'CN' },
];

export const countriesForUserAccount = [
  { name: 'Canada', value: 'CA' },
  { name: 'United States of America', value: 'US' },
];

export const signUpCountryOptions = [
  { name: 'Canada', value: 'CA' },
  { name: 'United States of America', value: 'US' },
  { name: 'Other', value: 'other' },
];

export const CURRENCIES = [
  { name: 'Canadian Dollar (CAD)', value: 'CAD' },
  { name: 'US Dollar (USD)', value: 'USD' },
  { name: 'Euro (EUR)', value: 'EUR' },
  { name: 'UK Sterling (GBP)', value: 'GBP' },
  { name: 'Australian Dollar (AUD)', value: 'AUD' },
  { name: 'Bulgarian Lev (BGN)', value: 'BGN' },
  { name: 'Chinese Yuan (CNY)', value: 'CNY' },
  { name: 'Czech Koruna (CZK)', value: 'CZK' },
  { name: 'Danish Krone (DKK)', value: 'DKK' },
  { name: 'Israeli New Shekel (ILS)', value: 'ILS' },
  { name: 'Indian Rupee (INR)', value: 'INR' },
  { name: 'Japanese Yen (JPY)', value: 'JPY' },
  { name: 'Kenyan Shilling (KES)', value: 'KES' },
  { name: 'Mexican Peso (MXN)', value: 'MXN' },
  { name: 'New Zealand Dollar (NZD)', value: 'NZD' },
  { name: 'Philippine Peso (PHP)', value: 'PHP' },
  { name: 'Qatari Rial (QAR)', value: 'QAR' },
  { name: 'Saudi Riyal (SAR)', value: 'SAR' },
  { name: 'South African Rand (ZAR)', value: 'ZAR' },
  { name: 'Swiss Franc (CHF)', value: 'CHF' },
  { name: 'Thai Baht (THB)', value: 'THB' },
  { name: 'Turkish Lira (TRY)', value: 'TRY' },
  { name: 'Ugandan Shilling (UGX)', value: 'UGX' },
  { name: 'United Arab Emirates Dirham (AED)', value: 'AED' },
];

export const CURRENCIES_LIST = CURRENCIES.reduce((acc, currency) => ({ ...acc, [currency.value]: currency }), {});

export const CURRENCY_CODES = CURRENCIES.reduce((acc, { value }) => ({ ...acc, [value]: value }), {});

export const SUPPLIER_SPECIFIC_COUNTRIES = {
  CA: { name: 'Canada', code: 'CA' },
  US: { name: 'United States of America', shortName: 'USA', code: 'US' },
  GB: { name: 'United Kingdom', shortName: 'UK', code: 'GB' },
  MX: { name: 'Mexico', code: 'MX' },
  CN: { name: 'China', code: 'CN' },
  EU: { name: 'Eurozone', code: 'EU' },
  PH: { name: 'Philippines', code: 'PH' },
  AU: { name: 'Australia', code: 'AU' },
  DK: { name: 'Denmark', code: 'DK' },
  IN: { name: 'India', code: 'IN' },
};

export const EU_COUNTRY_CODES = [
  'AT',
  'BE',
  'CY',
  'DE',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PT',
  'SI',
  'SK',
];

export const industryGroups = {
  E_COMMERCE_RETAIL: 'E_COMMERCE_RETAIL',
  SOFTWARE: 'SOFTWARE',
  PROFESSIONAL_SERVICE: 'PROFESSIONAL_SERVICE',
  WHOLESALE_TRADE: 'WHOLESALE_TRADE',
  MANUFACTURING: 'MANUFACTURING',
  OTHER_SERVICES: 'OTHER_SERVICES',
};

export const mainIndustries = [
  { name: 'E-commerce/Retail', value: industryGroups.E_COMMERCE_RETAIL },
  { name: 'Software', value: industryGroups.SOFTWARE },
  { name: 'Professional Service', value: industryGroups.PROFESSIONAL_SERVICE },
  { name: 'Wholesale Trade', value: industryGroups.WHOLESALE_TRADE },
  { name: 'Manufacturing', value: industryGroups.MANUFACTURING },
  { name: 'Other Services', value: industryGroups.OTHER_SERVICES },
];

export const industries = [
  {
    name: 'Accountants & Bookkeepers',
    value: 'ACCOUNTANTS_AND_BOOKKEEPERS',
    groupIds: [industryGroups.PROFESSIONAL_SERVICE],
  },
  {
    name: 'Administrative, Waste Management and Remediation Services',
    value: 'ADMINISTRATIVE_WASTE_MANAGEMENT_AND_REMEDIATION_SERVICES',
    groupIds: [industryGroups.OTHER_SERVICES],
  },
  {
    name: 'Agriculture, Forestry, Fishing and Hunting',
    value: 'AGRICULTURE_FORESTRY_FISHING_AND_HUNTING',
    groupIds: [industryGroups.OTHER_SERVICES],
  },
  {
    name: 'Arts, Entertainment and Recreation',
    value: 'ARTS_ENTERTAINMENT_AND_RECREATION',
    groupIds: [industryGroups.OTHER_SERVICES],
  },
  {
    name: 'Automotive',
    value: 'AUTOMOTIVE',
    groupIds: [
      industryGroups.E_COMMERCE_RETAIL,
      industryGroups.SOFTWARE,
      industryGroups.PROFESSIONAL_SERVICE,
      industryGroups.MANUFACTURING,
      industryGroups.OTHER_SERVICES,
    ],
  },
  {
    name: 'Baby',
    value: 'BABY',
    groupIds: [industryGroups.E_COMMERCE_RETAIL, industryGroups.SOFTWARE, industryGroups.WHOLESALE_TRADE],
  },
  {
    name: 'Cannabis, Alcohol and Tobacco',
    value: 'CANNABIS_ALCOHOL_TOBACCO',
    groupIds: [industryGroups.E_COMMERCE_RETAIL, industryGroups.OTHER_SERVICES],
  },
  {
    name: 'Clothing and Accessories',
    value: 'CLOTHING_AND_ACCESSORIES',
    groupIds: [
      industryGroups.E_COMMERCE_RETAIL,
      industryGroups.SOFTWARE,
      industryGroups.PROFESSIONAL_SERVICE,
      industryGroups.WHOLESALE_TRADE,
      industryGroups.MANUFACTURING,
    ],
  },
  { name: 'Consulting', value: 'CONSULTING', groupIds: [industryGroups.PROFESSIONAL_SERVICE] },
  { name: 'Construction', value: 'CONSTRUCTION', groupIds: [industryGroups.OTHER_SERVICES] },
  {
    name: 'Design and Digital Agencies',
    value: 'DESIGN_AND_DIGITAL_AGENCIES',
    groupIds: [industryGroups.PROFESSIONAL_SERVICE],
  },
  { name: 'Educational Services', value: 'EDUCATIONAL_SERVICES', groupIds: [industryGroups.OTHER_SERVICES] },
  {
    name: 'Electronics',
    value: 'ELECTRONICS',
    groupIds: [
      industryGroups.E_COMMERCE_RETAIL,
      industryGroups.SOFTWARE,
      industryGroups.PROFESSIONAL_SERVICE,
      industryGroups.WHOLESALE_TRADE,
      industryGroups.MANUFACTURING,
    ],
  },
  { name: 'Finance and Insurance', value: 'FINANCE_AND_INSURANCE', groupIds: [industryGroups.OTHER_SERVICES] },
  {
    name: 'Food Services and Accomodation',
    value: 'FOOD_SERVICES_AND_ACCOMODATION',
    groupIds: [industryGroups.OTHER_SERVICES],
  },

  {
    name: 'Grocery',
    value: 'GROCERY',
    groupIds: [
      industryGroups.E_COMMERCE_RETAIL,
      industryGroups.SOFTWARE,
      industryGroups.PROFESSIONAL_SERVICE,
      industryGroups.WHOLESALE_TRADE,
      industryGroups.MANUFACTURING,
      industryGroups.OTHER_SERVICES,
    ],
  },
  {
    name: 'Health and Personal Care',
    value: 'HEALTH_AND_PERSONAL_CARE',
    groupIds: [
      industryGroups.E_COMMERCE_RETAIL,
      industryGroups.SOFTWARE,
      industryGroups.PROFESSIONAL_SERVICE,
      industryGroups.WHOLESALE_TRADE,
      industryGroups.MANUFACTURING,
      industryGroups.OTHER_SERVICES,
    ],
  },
  {
    name: 'Home and Kitchen',
    value: 'HOME_AND_KITCHEN',
    groupIds: [
      industryGroups.E_COMMERCE_RETAIL,
      industryGroups.SOFTWARE,
      industryGroups.PROFESSIONAL_SERVICE,
      industryGroups.WHOLESALE_TRADE,
      industryGroups.MANUFACTURING,
    ],
  },
  {
    name: 'Hospitality',
    value: 'HOSPITALITY',
    groupIds: [industryGroups.PROFESSIONAL_SERVICE, industryGroups.MANUFACTURING, industryGroups.OTHER_SERVICES],
  },
  { name: 'Lottery and Gambling', value: 'LOTTERY_AND_GAMBLING', groupIds: [industryGroups.OTHER_SERVICES] },

  {
    name: 'Luxury Beauty',
    value: 'LUXURY_BEAUTY',
    groupIds: [
      industryGroups.E_COMMERCE_RETAIL,
      industryGroups.SOFTWARE,
      industryGroups.PROFESSIONAL_SERVICE,
      industryGroups.WHOLESALE_TRADE,
      industryGroups.MANUFACTURING,
    ],
  },
  { name: 'Movies and TV', value: 'MOVIES_AND_TV', groupIds: [industryGroups.OTHER_SERVICES] },
  {
    name: 'Musical Instruments, Stage and Studio',
    value: 'MUSICAL_INSTRUMENTS_STAGE_AND_STUDIO',
    groupIds: [industryGroups.OTHER_SERVICES],
  },
  {
    name: 'Office Products',
    value: 'OFFICE_PRODUCTS',
    groupIds: [
      industryGroups.E_COMMERCE_RETAIL,
      industryGroups.SOFTWARE,
      industryGroups.PROFESSIONAL_SERVICE,
      industryGroups.WHOLESALE_TRADE,
      industryGroups.MANUFACTURING,
    ],
  },
  { name: 'Online Pharmacy', value: 'ONLINE_PHARMACY', groupIds: [industryGroups.OTHER_SERVICES] },
  {
    name: 'Patio, Lawn and Garden',
    value: 'PATIO_LAWN_AND_GARDEN',
    groupIds: [
      industryGroups.E_COMMERCE_RETAIL,
      industryGroups.SOFTWARE,
      industryGroups.PROFESSIONAL_SERVICE,
      industryGroups.WHOLESALE_TRADE,
      industryGroups.MANUFACTURING,
    ],
  },
  {
    name: 'Pet Supplies',
    value: 'PET_SUPPLIES',
    groupIds: [
      industryGroups.E_COMMERCE_RETAIL,
      industryGroups.SOFTWARE,
      industryGroups.PROFESSIONAL_SERVICE,
      industryGroups.WHOLESALE_TRADE,
      industryGroups.MANUFACTURING,
    ],
  },
  {
    name: 'Real Estate, Rental and Leasing',
    value: 'REAL_ESTATE_RENTAL_AND_LEASING',
    groupIds: [industryGroups.OTHER_SERVICES],
  },
  {
    name: 'Repair and Maintenance Services',
    value: 'REPAIR_AND_MAINTENANCE_SERVICES',
    groupIds: [industryGroups.OTHER_SERVICES],
  },
  {
    name: 'Scientific and Technical Services',
    value: 'SCIENTIFIC_AND_TECHNICAL_SERVICES',
    groupIds: [industryGroups.OTHER_SERVICES],
  },
  {
    name: 'Sports and Outdoors',
    value: 'SPORTS_AND_OUTDOORS',
    groupIds: [
      industryGroups.E_COMMERCE_RETAIL,
      industryGroups.SOFTWARE,
      industryGroups.PROFESSIONAL_SERVICE,
      industryGroups.WHOLESALE_TRADE,
      industryGroups.MANUFACTURING,
    ],
  },
  {
    name: 'Tools and Home Improvement',
    value: 'TOOLS_AND_HOME_IMPROVEMENT',
    groupIds: [
      industryGroups.E_COMMERCE_RETAIL,
      industryGroups.SOFTWARE,
      industryGroups.PROFESSIONAL_SERVICE,
      industryGroups.WHOLESALE_TRADE,
      industryGroups.MANUFACTURING,
    ],
  },
  {
    name: 'Toys and Games',
    value: 'TOYS_AND_GAMES',
    groupIds: [
      industryGroups.E_COMMERCE_RETAIL,
      industryGroups.SOFTWARE,
      industryGroups.PROFESSIONAL_SERVICE,
      industryGroups.WHOLESALE_TRADE,
      industryGroups.MANUFACTURING,
    ],
  },
  {
    name: 'Transportation and Warehousing',
    value: 'TRANSPORTATION_AND_WAREHOUSING',
    groupIds: [industryGroups.OTHER_SERVICES],
  },
  { name: 'Utilities', value: 'UTILITIES', groupIds: [industryGroups.OTHER_SERVICES] },
  { name: 'Weapons', value: 'WEAPONS', groupIds: [industryGroups.OTHER_SERVICES] },
  {
    name: 'Other',
    value: 'OTHER',
    groupIds: [
      industryGroups.E_COMMERCE_RETAIL,
      industryGroups.SOFTWARE,
      industryGroups.PROFESSIONAL_SERVICE,
      industryGroups.WHOLESALE_TRADE,
      industryGroups.MANUFACTURING,
      industryGroups.OTHER_SERVICES,
    ],
  },
];

export const userRoles = {
  ACCOUNT_MANAGER: 'ACCOUNT_MANAGER',
  DIRECTOR: 'DIRECTOR',
  SHAREHOLDER: 'SHAREHOLDER',
  SIGNING_OFFICER: 'SIGNING_OFFICER',
  ACCOUNT_OWNER: 'ACCOUNT_OWNER',
};
export const userRolesOptions = [
  { name: 'Account manager', value: userRoles.ACCOUNT_MANAGER },
  { name: 'Director', value: userRoles.DIRECTOR },
  { name: 'Shareholder', value: userRoles.SHAREHOLDER },
  { name: 'Signing officer', value: userRoles.SIGNING_OFFICER },
  { name: 'Account owner', value: userRoles.ACCOUNT_OWNER },
];

export const USER_ROLE_TYPES = {
  readOnly: 'read_only',
  bookkeeper: 'bookkeeper',
  accountOwner: 'account_owner',
  admin: 'admin',
  member: 'member',
};

export const SCOPE = {
  manageAssignedCards: 'manage_assigned_cards',
  managePayments: 'manage_payments',
  manageBankAccounts: 'manage_bank_accounts',
  manageExternalAccounts: 'manage_external_accounts',
  manageLinesOfCredits: 'manage_lines_of_credits',
  assignCards: 'assign_cards',
  manageCards: 'manage_cards',
  unfreezeCards: 'unfreeze_cards',
  manageWalletAccounts: 'manage_wallet_accounts',
  manageRewards: 'manage_rewards',
  manageMembers: 'manage_members',
  manageAdmins: 'manage_admins',
  manageDocuments: 'manage_documents',
  manageAccountOwnership: 'manage_account_ownership',
  createPayeePayment: 'create_payee_payment',
  manageAccountingIntegrations: 'manage_accounting_integrations',
};

export const entityTypes = {
  SOLE_PROPRIETORSHIP: 'SOLE_PROPRIETORSHIP',
  CORPORATION: 'CORPORATION',
  PARTNERSHIP: 'PARTNERSHIP',
};

export const entityTypesOptions = [
  { name: 'Corporation', value: entityTypes.CORPORATION },
  { name: 'Partnership', value: entityTypes.PARTNERSHIP },
  { name: 'Sole Proprietorship', value: entityTypes.SOLE_PROPRIETORSHIP },
];

export const dates = Array.from(Array(31), (i, j) => j + 1);
export const months = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
};
const currentYear = new Date().getFullYear();
export const years = Array.from(Array(100), (i, j) => currentYear - j);

export const paymentStatus = {
  UNPAID: 'unpaid',
  PAID: 'paid',
  PROCESSING: 'processing',
  FAILED: 'failed',
  MISSED: 'missed',
};

export const monthlyOnlineRevenueOptions = [
  { name: 'Pre-revenue', value: '_PRE_REVENUE' },
  { name: '$1 - $10K', value: '_1_10K' },
  { name: '$10K - $30K', value: '_10K_30K' },
  { name: '$30K - $80K', value: '_30K_80K' },
  { name: '$80K - $200K', value: '_80K_200K' },
  { name: '$200K - $1M', value: '_200K_1M' },
  { name: '$1M+', value: '_1M_PLUS' },
];

export const referralSourceOptions = [
  { name: 'Accountant/Bookkeeper', value: 'accountant_bookkeeper' },
  { name: 'Another Loop User', value: 'another_loop_user' },
  { name: 'In The Mail', value: 'in_the_mail' },
  { name: 'Email/Newsletter', value: 'email_newsletter' },
  { name: 'Event', value: '_event' },
  { name: 'Google Search', value: 'google_search' },
  { name: 'LinkedIn', value: '_linkedin' },
  { name: 'Facebook', value: '_facebook' },
  { name: 'Twitter', value: '_twitter' },
  { name: 'Loop Employee', value: 'loop_employee' },
  { name: 'Other', value: '_other' },
];

export const authChallenges = {
  SMS_MFA: 'SMS_MFA',
  SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  MFA_SETUP: 'MFA_SETUP',
};

export const inventoryStorageOptions = [
  { name: 'Amazon warehouse', value: 'AMAZON_WAREHOUSE' },
  { name: 'Shopify fulfilment network', value: 'SHOPIFY_FULFILMENT_NETWORK' },
  { name: 'Self storage', value: 'SELF_STORAGE' },
  { name: '3rd party logistics provider', value: 'THIRD_PARTY_LOGISTICS_PROVIDER' },
  { name: 'Hybrid', value: 'HYBRID' },
  { name: 'Dropship', value: 'DROPSHIP' },
  { name: 'Other', value: 'OTHER' },
];

export const employeeTypeOptions = [
  { name: 'Employees', value: 'EMPLOYEES' },
  { name: 'Contractors', value: 'CONTRACTORS' },
  { name: 'Both', value: 'BOTH' },
  { name: 'Neither', value: 'NEITHER' },
];

export const ownersPaymentMethodOptions = [
  { name: 'Salaries', value: 'SALARIES' },
  { name: 'Dividends', value: 'DIVIDENDS' },
  { name: 'Salaries and Dividends', value: 'SALARIES_AND_DIVIDENDS' },
  { name: 'Other Method', value: 'OTHER_METHOD' },
  { name: 'Owners Not Paid', value: 'OWNERS_NOT_PAID' },
];

export const residentialStatusOptions = [
  { name: 'Own home with a mortgage', value: 'MORTGAGE' },
  { name: 'Renting', value: 'RENTAL' },
  { name: 'Own home outright mortgage paid off', value: 'PAID_MORTGAGE' },
  { name: 'Other', value: 'OTHER' },
];

export const onboardingStatus = {
  BANK_ACCOUNT: 'BANK_ACCOUNT',
  BUSINESS_ADDRESS: 'BUSINESS_ADDRESS',
  BUSINESS_DETAILS: 'BUSINESS_DETAILS',
  BUSINESS_CONTACTS: 'BUSINESS_CONTACTS',
  COMPLETED: 'COMPLETED',
  CRA: 'CRA',
  CREDIT_APPLICATION: 'CREDIT_APPLICATION',
  DETAILS_COMPLETED: 'DETAILS_COMPLETED',
  DOCUMENTS: 'DOCUMENTS',
  INTEGRATIONS: 'INTEGRATIONS',
  OWNER_PROFILE: 'OWNER_PROFILE',
  SHAREHOLDERS: 'SHAREHOLDERS',
  COMPLETED_PENDING_KYC: 'COMPLETED_PENDING_KYC',
};

export const applicationStatusLabels = {
  step1: `To get started, please continue to provide your business details. This will allow Loop to verify your business so that we can open your account and allow you to apply for a line of credit.`,
  step2: `Please continue to connect your bank account to Loop. Allowing Loop to view your banking information is a regulatory requirement that allows us to verify you. Loop uses leading software providers to retrieve your information - we will never store your banking credentials. Once your account is connected, we'll collect a few more details so we can provide your business with a no-risk and no-obligation funding offer.`,
  step3: `Now we've got all the information we need about your business, we can now determine the amount of financing we'll be able to offer with a line of credit. Please continue to submit your credit application data so we can provide your business with a no-risk and no-obligation funding offer.`,
  step4: `You're almost done submitting your credit application. All we need is for you to connect us to your Canada Revenue Agency account. This helps us to make sure we've got the right business and mitigate any potential issues such as fraud.`,
  step5: `Don't give up now! You've completed your application just need to submit your details so we can begin our review. If you have any questions, please feel free to reach out to our team.`,
  completed: `Great News - We've received your request and are currently analyzing your details 🔎. We will be in touch shortly `,
  // TODO: This is not used yet as we don't have rejection status for Credit Applications
  rejected: `Unfortunately , we are unable to approve your line of credit. If your circumstances improve after 30 days, let our team know and we can re-evaluate your application. `,
};

export const KYC_STATUS = {
  APPROVED: 'APPROVED',
  DRAFT: 'DRAFT',
  MANUAL_REVIEW: 'MANUAL_REVIEW',
  NOT_STARTED: 'NOT_STARTED',
  WAITING_FOR_USER_DATA: 'WAITING_FOR_USER_DATA',
  READY_TO_REVIEW: 'READY_TO_REVIEW',
  REJECTED: 'REJECTED',
  PENDING_USER_ACTION: 'PENDING_USER_ACTION',
  PENDING_DATA: 'PENDING_DATA',
};

export const CREDIT_ASSESSMENT_STATUS = {
  APPROVED: 'APPROVED',
  NOT_STARTED: 'NOT_STARTED',
  DRAFT: 'DRAFT',
  MANUAL_REVIEW: 'MANUAL_REVIEW',
  REJECTED: 'REJECTED',
};

export const CREDIT_APPLICATION_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  DRAFT: 'DRAFT',
  COMPLETED: 'COMPLETED',
  CLOSED: 'CLOSED',
  DEAD: 'DEAD',
};

export const onlineChannels = ['Magento', 'BigCommerce', 'SquareSpace', 'Other'];

export const TRANSACTION_STATUS = {
  POSTED: 'POSTED',
  PROCESSING: 'PROCESSING',
  PENDING: 'PENDING',
  DENIED: 'DENIED',
  FLAGGED_FOR_REVIEW: 'FLAGGED_FOR_REVIEW',
  HELD: 'HELD',
};

export const CREDIT_CARD_STATUS = {
  suspended: 'suspended',
  temporarilySuspended: 'temporarily_suspended',
  block: 'block',
  active: 'active',
  inactive: 'inactive',
  damaged: 'damaged',
  fraudulent: 'fraudulent',
  lost: 'lost',
  stolen: 'stolen',
  admin_suspended: 'admin_suspended',
};

export const CREDIT_CARD_STATES = {
  active: 'Active',
  frozen: 'Frozen',
  closed: 'Closed',
  activationRequired: 'Activation Required',
};

export const CREDIT_CARD_TYPES = {
  virtual: 'virtual',
  physical: 'physical',
};

export const PAYEE_TYPES = {
  NATIONAL: 'national',
  INTERNATIONAL: 'international',
};

export const PAYEE_PAYMENT_APPROVAL_STATUS = {
  approved: 'APPROVED',
  pendingApproval: 'PENDING_APPROVAL',
  rejected: 'REJECTED',
};

export const PAYEE_PAYMENT_STATUS = {
  pending: 'PENDING',
  processing: 'PROCESSING',
  posted: 'POSTED',
  failed: 'FAILED',
  denied: 'DENIED',
  draft: 'DRAFT',
  flagged_for_review: 'FLAGGED_FOR_REVIEW',
  held: 'HELD',
};

export const REDEEMED_GIFT_STATUS = {
  redeemed: 'redeemed',
  pending: 'pending',
  error: 'error',
};

export const PAYMENT_AND_DEPOSIT_TYPES = ['DEPOSIT', 'MONTHLY_PAYMENT', 'BALANCE_REPAYMENT'];

export const TRANSACTION_TYPES = {
  CARD: 'CARD',
  EFT: 'EFT',
  DOMESTIC: 'DOMESTIC',
  INTERNAL: 'INTERNAL',
  WITHDRAWAL: 'WITHDRAWAL',
  WITHDRAWAL_FEE: 'WITHDRAWAL_FEE',
  MONTHLY_PAYMENT: 'MONTHLY_PAYMENT',
  INTEREST_CHARGE: 'INTEREST_CHARGE',
  MONTHLY_FEE: 'MONTHLY_FEE',
  CANCELLATION: 'CANCELLATION',
  BALANCE_REPAYMENT: 'BALANCE_REPAYMENT',
  CORRECTION: 'CORRECTION',
  CREDIT: 'CREDIT',
  MONTHLY_PAYMENT_NSF: 'MONTHLY_PAYMENT_NSF',
  BALANCE_REPAYMENT_NSF: 'BALANCE_REPAYMENT_NSF',
  NSF_FEE: 'NSF_FEE',
  ROLLBACK: 'ROLLBACK',
  LATE_PAYMENT_FEE: 'LATE_PAYMENT_FEE',
  WIRE_FEE: 'WIRE_FEE',
  FEE: 'FEE',
  DEPOSIT: 'DEPOSIT',
};

export const CREDIT_CARD_EMPTY_STATES = {
  isExistingLocUser: 'isExistingLocUser',
  createCreditCard: 'createCreditCard',
  waitingBankAccountVerification: 'waitingBankAccountVerification',
  connectBankAccount: 'connectBankAccount',
  reviewInProgress: 'reviewInProgress',
  errorFetchingCards: 'errorFetchingCards',
  connectBankOrContactLoopAsLOCUser: 'connectBankOrContactLoopAsLOCUser',
  connectBankOrContactLoop: 'connectBankOrContactLoop',
};

export const CREDIT_CARD_LIMITS = {
  VIRTUAL: config.virtualCreditCardLimit,
  PHYSICAL: config.physicalCreditCardLimit,
};

export const defaultBankImage = 'https://loop-public.s3.ca-central-1.amazonaws.com/bank_logo_default.png';
export const bankImages = [
  { name: 'BMO', image: 'https://loop-public.s3.ca-central-1.amazonaws.com/bank_logo_bmo.png' },
  { name: 'CIBC', image: 'https://loop-public.s3.ca-central-1.amazonaws.com/bank_logo_cibc.png' },
  { name: 'RBC', image: 'https://loop-public.s3.ca-central-1.amazonaws.com/bank_logo_rbc.png' },
  { name: 'Scotiabank', image: 'https://loop-public.s3.ca-central-1.amazonaws.com/bank_logo_scotiabank.png' },
  { name: 'TD', image: 'https://loop-public.s3.ca-central-1.amazonaws.com/bank_logo_td.png' },
];

export const balancesOrder = ['CAD', 'USD', 'EUR', 'GBP'];

export const SUPPORTED_CURRENCIES = balancesOrder;

export const CARD_INVALID_ERROR = 'Card number is invalid';

export const CARD_LENGTH_ERROR = 'The card number you entered is incorrect. Please ensure it is 16 digits long.';

export const CARD_STARTING_DIGIT_ERROR =
  'The card number you entered does not match the selected card type. Please verify the card number and the card type.';

export const ID_DOCUMENT_OPTIONS = [{ name: 'Passport', value: 'passport' }];

export const TEAM_MEMBER_STATUS = {
  active: 'active',
  inactive: 'inactive',
  not_invited: 'not_invited',
  pending_info: 'pending_info',
  pending_verification: 'pending_verification',
};

export const TEAM_INVITATION_STATUS = {
  pending: 'pending',
  pending_info: 'pending_info',
  pending_verification: 'pending_verification',
  accepted: 'accepted',
};

export const TRANSACTION_PRODUCT_TYPES = {
  creditCard: 'credit_card',
  wallet: 'wallet',
  lineOfCredit: 'line_of_credit',
};

export const TRANSACTION_PRODUCT_TYPE_NAMES = {
  credit_card: 'Card',
  wallet: 'Account',
  line_of_credit: 'Line of Credit',
};

export const LOCAL_STORAGE_KEYS = {
  connectBankAccountModal: 'connectBankAccountModal',
  themeMode: 'themeMode',
  solidMigrationModalAlreadyShown: 'solidMigrationModalAlreadyShown',
};

export const QBO_TRANSACTION_STATUSES = ['All', 'Pending', 'Posted', 'Error'];

export const QBO_TRANSACTION_TYPES = ['Purchase', 'Deposit'];

export const CONTACT_PERMISSIONS = {
  createVirtualCard: 'Create Virtual Card',
  createPhysicalCard: 'Create Physical Card',
  viewPayeePayment: 'View Payee Payment',
  createPayee: 'Create Payee',
  createPayeePayment: 'Create Payee Payment',
  approvePayeePayment: 'Approve Payee Payment',
};

export const MAX_EXPORT_TRANSACTIONS = 300;
export const CAUSES_EXPORT_FAILS = {
  retrieving: 'RetrievingData',
  tooMany: 'TooManyTransactions',
};

export const notificationTooltips = {
  card: {
    approvedCardTransaction: 'Sent when a new card transaction is created',
    refundedCardTransaction: 'Sent when a card transaction is refunded',
    cancelledCardTransaction: 'Sent when a card transaction is cancelled',
    memberAddedToCard: 'Sent when a card is shared with a team member',
    newCardCreated: 'Sent when a new card is created',
    paymentDue: 'Sent 1 day and 7 days before a payment is due for a Loop Card',
  },
  account: {
    depositReceived: 'Sent when a transfer into the account has been deposited',
    withdrawalCreated: 'Sent when a withdrawal from the account has been initiated',
  },
  capital: {
    paymentDue: 'Sent 1 day and 3 days before a payment is due for a loan account',
  },
  payment: {
    payeePaymentCreated: 'Sent when instruction to make a payment to a Payee has been received',
    payeePaymentSent: 'Sent when a payment has successfully been made to a Payee',
  },
};

export const PAYEE_RECORD_TYPES = {
  CRAAccount: 'CRAAccount',
  CreditCardPayee: 'CreditCardPayee',
  Supplier: 'Supplier',
};

export const PAYMENT_ACCOUNT_TYPES = {
  LineOfCredit: 'LineOfCredit',
  CreditCard: 'CreditCardInternalAccount',
};

export const ADDRESS_CATEGORY = {
  DA: 'DA',
  S1: 'S1',
  S2: 'S2',
  OA: 'OA',
  RP: 'RP',
  DEFAULT: 'DEFAULT',
  CA: 'CA',
  US: 'US',
};

// https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
export const GOOGLE_ADDRESS_ENTITIES = {
  unitNumber: 'subpremise',
  streetNumber: 'street_number',
  street: 'route',
  city: 'locality',
  city2: 'postal_town',
  postalCode: 'postal_code',
  countrySubdivision: 'administrative_area_level_1',
  countrySubdivision2: 'administrative_area_level_2',
  country: 'country',
};

export const DEFAULT_EXCHANGE_RATE_AMOUNT = 1_000_00;

export const MIN_EXCHANGE_RATE_AMOUNT = 100;

// CurrencyCloud has a minimum limit for conversion of 1 GBP
export const MIN_CONVERSION_AMOUNT = 200;
export const CURRENCIES_WITH_CONVERSION_DATE_LIMITS = ['INR', 'IDR', 'MYR', 'PHP'];

export const solidTransactionsAmountOptions = [
  { name: '$0 - $0', value: '0_0' },
  { name: '$0.01 - $5,000', value: '001_5K' },
  { name: '$5,000.01 - $10,000', value: '5K1_10K' },
  { name: '$10,000.01 - $20,000', value: '10K1_20K' },
  { name: '$20,000.01 - $30,000', value: '20K1_30K' },
  { name: '$30,000.01 - $60,000', value: '30K1_60K' },
  { name: '$60,000.01 - $100,000', value: '60K1_100K' },
  { name: '$100,000.01 - $150,000', value: '100K1_150K' },
  { name: '$150,000.01 - $225,000', value: '150K1_225K' },
  { name: '$300,000.01 - $1,000,000', value: '300K1_1M' },
  { name: '$1,000,000.01 - $2,000,000', value: '1M1_2M' },
  { name: '$2,000,000+', value: '2M+' },
];

export const solidTransactionsVolumeOptions = [
  { name: '0 - 0', value: '0_0' },
  { name: '1 - 10', value: '1_10' },
  { name: '11 - 20', value: '11_20' },
  { name: '21 - 30', value: '21_30' },
  { name: '31 - 40', value: '31_40' },
  { name: '41 - 60', value: '41_60' },
  { name: '61 - 100', value: '61_100' },
  { name: '101 - 150', value: '101_150' },
  { name: '151 - 250', value: '151_250' },
  { name: '251 - 500', value: '251_500' },
  { name: '501 - 1000', value: '501_1000' },
  { name: '1000+', value: '1000+' },
];

export const EXTERNAL_TRANSACTION_CLEARING_MESSAGE = 'Transaction will clear within 4 days.';

export const INR_REASONS = [
  { name: 'Advertising', value: 'advertising' },
  { name: 'Advisor Fees', value: 'advisor_fees' },
  { name: 'Construction', value: 'construction' },
  { name: 'Education', value: 'education' },
  { name: 'Exports', value: 'exports' },
  { name: 'Family', value: 'family' },
  { name: 'Fund Investment', value: 'fund_investment' },
  { name: 'Goods', value: 'goods' },
  { name: 'Hotel', value: 'hotel' },
  { name: 'Insurance Claims', value: 'insurance_claims' },
  { name: 'Insurance Premium', value: 'insurance_premium' },
  { name: 'Loan Repayment', value: 'loan_repayment' },
  { name: 'Medical', value: 'medical' },
  { name: 'Other Fees', value: 'other_fees' },
  { name: 'Property Purchase', value: 'property_purchase' },
  { name: 'Property Rental', value: 'property_rental' },
  { name: 'Royalties', value: 'royalties' },
  { name: 'Services', value: 'services' },
  { name: 'Share Investment', value: 'share_investment' },
  { name: 'Tax', value: 'tax' },
  { name: 'Transfer', value: 'transfer' },
  { name: 'Travel', value: 'travel' },
  { name: 'Utilities', value: 'utilities' },
];
export const ALL_PERMISSIONS = [
  { label: 'Manage Cards', value: 'manage_cards' },
  { label: 'View Cards', value: 'view_cards' },
  { label: 'View Card Statements', value: 'view_card_statements' },
  { label: 'Manage Assigned Cards', value: 'manage_assigned_cards' },
  { label: 'Assign Cards', value: 'assign_cards' },
  { label: 'Unfreeze Cards', value: 'unfreeze_cards' },
  { label: 'Create Virtual Cards', value: 'create_virtual_card' },
  { label: 'Create Physical Card', value: 'create_physical_card' },
  { label: 'Manage Card Authorization Settings', value: 'manage_card_authorization_settings' },
  { label: 'Manage Card Limits', value: 'manage_card_limits' },
  { label: 'Pay Card Balance', value: 'pay_card_balance' },
  { label: 'View Card Transactions', value: 'view_card_transactions' },
  { label: 'Manage Card Transaction', value: 'manage_card_transaction' },
  { label: 'Manage Wallet Accounts', value: 'manage_wallet_accounts' },
  { label: 'View All Account Balances', value: 'view_all_account_balances' },
  { label: 'View Account Details', value: 'view_account_details' },
  { label: 'View Account Statements', value: 'view_account_statements' },
  { label: 'Freeze Accounts', value: 'freeze_accounts' },
  { label: 'Add Funds To Account', value: 'add_funds_to_account' },
  {
    label: 'Withdraw Funds To Verified Connected Bank Accounts',
    value: 'withdraw_funds_to_verified_connected_bank_accounts',
  },
  { label: 'Create FX Conversions', value: 'create_fx_conversions' },
  { label: 'Create Account Linked Cards', value: 'create_account_linked_cards' },
  { label: 'View Account Transactions', value: 'view_account_transactions' },
  { label: 'Manage Account Transaction', value: 'manage_account_transaction' },
  { label: 'Manage Bank Accounts', value: 'manage_bank_accounts' },
  { label: 'Manage Payments', value: 'manage_payments' },
  { label: 'Create Payee', value: 'create_payee' },
  { label: 'View Payees', value: 'view_payees' },
  { label: 'View Payee Payment', value: 'view_payee_payment' },
  { label: 'Create Payee Payment', value: 'create_payee_payment' },
  { label: 'Request Payee Payments', value: 'request_payee_payments' },
  { label: 'Approve Payee Payment', value: 'approve_payee_payment' },
  { label: 'Manage Lines Of Credits', value: 'manage_lines_of_credits' },
  { label: 'View Statements', value: 'view_statements' },
  {
    label: 'Withdraw Line Of Credit Funds To Verified Connected Bank Accounts',
    value: 'withdraw_line_of_credit_funds_to_verified_connected_bank_accounts',
  },
  { label: 'Pay Balance To Line Of Credit', value: 'pay_balance_to_line_of_credit' },
  { label: 'View Line Of Credit Transactions', value: 'view_line_of_credit_transactions' },
  { label: 'Manage Line Of Credit Transaction', value: 'manage_line_of_credit_transaction' },
  { label: 'Manage Team Settings', value: 'manage_team_settings' },
  { label: 'Manage Documents', value: 'manage_documents' },
  { label: 'Upload Documents', value: 'upload_documents' },
  { label: 'Manage Members', value: 'manage_members' },
  { label: 'Manage Admins', value: 'manage_admins' },
  { label: 'Manage Account Ownership', value: 'manage_account_ownership' },
  { label: 'Manage External Bank Accounts', value: 'manage_external_accounts' },
  { label: 'Manage Accounting Integrations', value: 'manage_accounting_integrations' },
  { label: 'Manage Rewards', value: 'manage_rewards' },
  { label: 'View Rewards', value: 'view_rewards' },
  { label: 'Redeem Rewards', value: 'redeem_rewards' },
  { label: 'View Offers', value: 'view_offers' },
  { label: 'Activate Or Deactivate Offers', value: 'activate_or_deactivate_offers' },
  { label: 'View Refer And Earn', value: 'view_refer_and_earn' },
  { label: 'Manage Payors', value: 'manage_payors' },
  { label: 'View Payors', value: 'view_payors' },
  { label: 'Create Payors', value: 'create_payors' },
  { label: 'Create Payor Payments Request', value: 'create_payor_payments_request' },
  { label: 'View Payor Payments Requested', value: 'view_payor_payments_requested' },
  { label: 'Manage Approvals', value: 'manage_approvals' },
  { label: 'Create Expense Reimbursements', value: 'create_expense_reimbursements' },
  { label: 'Approve Expense Reimbursements', value: 'approve_expense_reimbursements' },
  { label: 'Manage Expense Reimbursements', value: 'manage_expense_reimbursements' },
  { label: 'View Card Expenses', value: 'view_card_expenses' },
  { label: 'Manage Card Expenses', value: 'manage_card_expenses' },
  { label: 'Approve Card Expenses', value: 'approve_card_expenses' },
];

export const CARD_PERMISSIONS = [
  { label: 'Manage and View All Cards', value: 'manage_cards' },
  { label: 'View Cards', value: 'view_cards' },
  { label: 'View Card Statements', value: 'view_card_statements' },
  { label: 'Manage Assigned Cards', value: 'manage_assigned_cards' },
  { label: 'Assign Cards', value: 'assign_cards' },
  { label: 'Unfreeze Cards', value: 'unfreeze_cards' },
  { label: 'Create Virtual Cards', value: 'create_virtual_card' },
  { label: 'Create Physical Card', value: 'create_physical_card' },
  { label: 'Manage Card Authorization Settings', value: 'manage_card_authorization_settings' },
  { label: 'Manage Card Limits', value: 'manage_card_limits' },
  { label: 'Pay Card Balance', value: 'pay_card_balance' },
  { label: 'View Card Transactions', value: 'view_card_transactions' },
  { label: 'Manage Card Transaction', value: 'manage_card_transaction' },
];

export const INITIAL_CARD_PERMISSIONS = [
  { label: 'Manage and View All Cards', value: 'manage_cards' },
  { label: 'View All Cards', value: 'view_cards' },
  { label: 'Create Virtual Cards', value: 'create_virtual_card' },
  { label: 'Create Physical Cards', value: 'create_physical_card' },
];

export const ACCOUNT_PERMISSIONS = [
  { label: 'Manage Wallet Accounts', value: 'manage_wallet_accounts' },
  { label: 'View All Account Balances', value: 'view_all_account_balances' },
  { label: 'View Account Details', value: 'view_account_details' },
  { label: 'View Account Statements', value: 'view_account_statements' },
  { label: 'Freeze Accounts', value: 'freeze_accounts' },
  { label: 'Add Funds to Account', value: 'add_funds_to_account' },
  {
    label: 'Withdraw Funds to Verified Connected Bank Accounts',
    value: 'withdraw_funds_to_verified_connected_bank_accounts',
  },
  { label: 'Create FX Conversions', value: 'create_fx_conversions' },
  { label: 'Create Account Linked Cards', value: 'create_account_linked_cards' },
  { label: 'View Account Transactions', value: 'view_account_transactions' },
  { label: 'Manage Account Transaction', value: 'manage_account_transaction' },
];

export const INITIAL_ACCOUNT_PERMISSIONS = [
  { label: 'View and Manage All Accounts', value: 'manage_wallet_accounts' },
  { label: 'View All Account Balances', value: 'view_all_account_balances' },
  { label: 'View Account Details', value: 'view_account_details' },
  { label: 'Transfer Funds In', value: 'add_funds_to_account' },
  {
    label: 'Transfer Funds Out',
    value: 'withdraw_funds_to_verified_connected_bank_accounts',
  },
  { label: 'Create FX Conversions', value: 'create_fx_conversions' },
];

export const PAYMENT_PERMISSIONS = [
  { label: 'View and Manage All Payments', value: 'manage_payments' },
  { label: 'Manage Payments', value: 'manage_payments' },
  { label: 'Create Payee', value: 'create_payee' },
  { label: 'View Payees', value: 'view_payees' },
  { label: 'View All Outgoing Payments', value: 'view_payee_payment' },
  { label: 'Create Payments', value: 'create_payee_payment' },
  { label: 'Request Payments', value: 'request_payee_payments' },
  { label: 'Approve Payments', value: 'approve_payee_payment' },
];

export const INITIAL_PAYMENT_PERMISSIONS = [
  { label: 'View and Manage All Payments', value: 'manage_payments' },
  { label: 'Create Payee', value: 'create_payee' },
  { label: 'View All Outgoing Payments', value: 'view_payee_payment' },
  { label: 'Create Payments', value: 'create_payee_payment' },
  { label: 'Approve Payments', value: 'approve_payee_payment' },
];

export const LINES_OF_CREDIT_PERMISSIONS = [
  { label: 'View and Manage All Lines of Credit', value: 'manage_lines_of_credits' },
  { label: 'View Statements', value: 'view_statements' },
  {
    label: 'Withdraw Line of Credit Funds to Verified Connected Bank Accounts',
    value: 'withdraw_line_of_credit_funds_to_verified_connected_bank_accounts',
  },
  { label: 'Pay Balance to Line of Credit', value: 'pay_balance_to_line_of_credit' },
  { label: 'View Line of Credit Transactions', value: 'view_line_of_credit_transactions' },
  { label: 'Manage Line of Credit Transaction', value: 'manage_line_of_credit_transaction' },
];

export const INITIAL_LINES_OF_CREDIT_PERMISSIONS = [
  { label: 'View and Manage All Lines of Credit', value: 'manage_lines_of_credits' },
];

export const TEAMS_AND_SETTINGS_PERMISSIONS = [
  { label: 'Manage Team Settings', value: 'manage_team_settings' },
  { label: 'Manage Documents', value: 'manage_documents' },
  { label: 'Upload Documents', value: 'upload_documents' },
  { label: 'Manage Members', value: 'manage_members' },
  { label: 'Manage Admins', value: 'manage_admins' },
  { label: 'Manage Account Ownership', value: 'manage_account_ownership' },
  { label: 'Manage Sales Integrations', value: 'manage_external_accounts' },
  { label: 'Manage Accounting Integrations', value: 'manage_accounting_integrations' },
  { label: 'Manage Bank Accounts', value: 'manage_bank_accounts' },
];

export const INITIAL_TEAMS_AND_SETTINGS_PERMISSIONS = [
  { label: 'View and Manage All Team Settings', value: 'manage_team_settings' },
  { label: 'Manage Sales Integrations', value: 'manage_external_accounts' },
  { label: 'Manage Accounting Integrations', value: 'manage_accounting_integrations' },
  { label: 'Manage Connected Bank Accounts', value: 'manage_bank_accounts' },
];

export const REWARDS_AND_OFFERS_PERMISSIONS = [
  { label: 'Manage Rewards', value: 'manage_rewards' },
  { label: 'View Rewards', value: 'view_rewards' },
  { label: 'Redeem Rewards', value: 'redeem_rewards' },
  { label: 'View Offers', value: 'view_offers' },
  { label: 'Activate or Deactivate Offers', value: 'activate_or_deactivate_offers' },
  { label: 'View Refer and Earn', value: 'view_refer_and_earn' },
];

export const BILLING_PERMISSIONS = [
  { label: 'View and Manage All Billing', value: 'manage_payors' },
  { label: 'View All Payors', value: 'view_payors' },
  { label: 'Create Payors', value: 'create_payors' },
  { label: 'Send Payment Requests', value: 'create_payor_payments_request' },
  { label: 'View All Payment Requests', value: 'view_payor_payments_requested' },
];

export const APPROVAL_PERMISSIONS = [
  { label: 'Manage and View All Approvals', value: 'manage_approvals' },
  { label: 'Create Expense Reimbursements', value: 'create_expense_reimbursements' },
  { label: 'Approve Expense Reimbursements', value: 'approve_expense_reimbursements' },
  { label: 'Manage Expense Reimbursements', value: 'manage_expense_reimbursements' },
  { label: 'View Card Expenses', value: 'view_card_expenses' },
  { label: 'Manage Card Expenses', value: 'manage_card_expenses' },
  { label: 'Approve Card Expenses', value: 'approve_card_expenses' },
];

export const ACCOUNT_OWNER_PERMISSIONS = ALL_PERMISSIONS;

export const ADMIN_PERMISSIONS = ALL_PERMISSIONS.filter((p) => p.value !== 'manage_admins');

export const READ_ONLY_PERMISSIONS = [
  { label: 'View Card Expenses', value: 'view_card_expenses' },
  { label: 'View Cards', value: 'view_cards' },
  { label: 'View Card Statements', value: 'view_card_statements' },
  { label: 'View Card Transactions', value: 'view_card_transactions' },
  { label: 'View All Account Balances', value: 'view_all_account_balances' },
  { label: 'View Account Details', value: 'view_account_details' },
  { label: 'View Account Statements', value: 'view_account_statements' },
  { label: 'View Payees', value: 'view_payees' },
  { label: 'View Payee Payment', value: 'view_payee_payment' },
  { label: 'View Statements', value: 'view_statements' },
  { label: 'View Line of Credit Transactions', value: 'view_line_of_credit_transactions' },
  { label: 'View Rewards', value: 'view_rewards' },
  { label: 'View Offers', value: 'view_offers' },
  { label: 'View Refer and Earn', value: 'view_refer_and_earn' },
  { label: 'View Payors', value: 'view_payors' },
  { label: 'View Payor Payments Requested', value: 'view_payor_payments_requested' },
  { label: 'View Account Statements', value: 'view_account_statements' },
];

export const BOOKKEEPER_PERMISSIONS = [
  { label: 'Manage Cards', value: 'manage_cards' },
  { label: 'View Cards', value: 'view_cards' },
  { label: 'View Card Statements', value: 'view_card_statements' },
  { label: 'Manage Assigned Cards', value: 'manage_assigned_cards' },
  { label: 'Assign Cards', value: 'assign_cards' },
  { label: 'Unfreeze Cards', value: 'unfreeze_cards' },
  { label: 'Create Virtual Cards', value: 'create_virtual_card' },
  { label: 'Create Physical Card', value: 'create_physical_card' },
  { label: 'Manage Card Authorization Settings', value: 'manage_card_authorization_settings' },
  { label: 'Manage Card Limits', value: 'manage_card_limits' },
  { label: 'Pay Card Balance', value: 'pay_card_balance' },
  { label: 'View Card Transactions', value: 'view_card_transactions' },
  { label: 'Manage Card Transaction', value: 'manage_card_transaction' },
  { label: 'Manage Wallet Accounts', value: 'manage_wallet_accounts' },
  { label: 'View All Account Balances', value: 'view_all_account_balances' },
  { label: 'View Account Details', value: 'view_account_details' },
  { label: 'View Account Statements', value: 'view_account_statements' },
  { label: 'Freeze Accounts', value: 'freeze_accounts' },
  { label: 'Add Funds To Account', value: 'add_funds_to_account' },
  {
    label: 'Withdraw Funds To Verified Connected Bank Accounts',
    value: 'withdraw_funds_to_verified_connected_bank_accounts',
  },
  { label: 'Create Fx Conversions', value: 'create_fx_conversions' },
  { label: 'Create Account Linked Cards', value: 'create_account_linked_cards' },
  { label: 'View Account Transactions', value: 'view_account_transactions' },
  { label: 'Manage Account Transaction', value: 'manage_account_transaction' },
  { label: 'Manage Bank Accounts', value: 'manage_bank_accounts' },
  { label: 'Manage Payments', value: 'manage_payments' },
  { label: 'Create Payee', value: 'create_payee' },
  { label: 'View Payees', value: 'view_payees' },
  { label: 'View Payee Payment', value: 'view_payee_payment' },
  { label: 'Create Payee Payment', value: 'create_payee_payment' },
  { label: 'Request Payee Payments', value: 'request_payee_payments' },
  { label: 'Approve Payee Payment', value: 'approve_payee_payment' },
  { label: 'Manage Lines of Credits', value: 'manage_lines_of_credits' },
  { label: 'View Statements', value: 'view_statements' },
  {
    label: 'Withdraw Line of Credit Funds to Verified Connected Bank Accounts',
    value: 'withdraw_line_of_credit_funds_to_verified_connected_bank_accounts',
  },
  { label: 'Pay Balance to Line of Credit', value: 'pay_balance_to_line_of_credit' },
  { label: 'View Line of Credit Transactions', value: 'view_line_of_credit_transactions' },
  { label: 'Manage Line of Credit Transaction', value: 'manage_line_of_credit_transaction' },
  { label: 'Manage Payors', value: 'manage_payors' },
  { label: 'View Payors', value: 'view_payors' },
  { label: 'Create Payors', value: 'create_payors' },
  { label: 'Create Payor Payments Request', value: 'create_payor_payments_request' },
  { label: 'View Payor Payments Requested', value: 'view_payor_payments_requested' },
  { label: 'Manage Approvals', value: 'manage_approvals' },
  { label: 'Create Expense Reimbursements', value: 'create_expense_reimbursements' },
  { label: 'Approve Expense Reimbursements', value: 'approve_expense_reimbursements' },
  { label: 'Manage Expense Reimbursements', value: 'manage_expense_reimbursements' },
  { label: 'View Card Expenses', value: 'view_card_expenses' },
  { label: 'Manage Card Expenses', value: 'manage_card_expenses' },
  { label: 'Approve Card Expenses', value: 'approve_card_expenses' },
  { label: 'Manage Accounting Integrations', value: 'manage_accounting_integrations' },
];

export const MEMBER_PERMISSIONS = {};
