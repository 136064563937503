import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import config from 'config';
import { SUBMIT_BUSINESS_DETAIL } from 'graphql/onboarding';
import { validateInteger } from 'utility/validators';
import {
  entityTypesOptions,
  mainIndustries,
  industries,
  monthlyOnlineRevenueOptions,
  referralSourceOptions,
} from 'constants/index';
import { ampTrackEvent } from 'amplitude';
import useSetPageTitle from 'hooks/useSetPageTitle';
import {
  TextField,
  Select,
  RadioField,
  TextArea,
  PhoneField,
  MaskedTextField,
  SubmitButton,
} from 'components/FormFields/v2';
import InfoTip1Icon from 'components/svg/InfoTip1';
import InfoTip2Icon from 'components/svg/InfoTip2';
import ArrowRight from 'components/svg/ArrowRight';
import GiftBoxWithCoin from 'components/svg/GiftBoxWithCoin';
import Loading from 'components/onboarding/Loading';
import Progress from 'components/onboarding/Progress';
import Layout from 'components/onboarding/Layout';
import InfoTip from 'components/onboarding/InfoTip';
import useOnboardingRedirect from 'hooks/useOnboardingRedirect';
import Button from 'components/Button';
import { ONBOARDING_STEPS } from 'components/onboarding/constants';

const Form = ({
  name,
  displayName,
  entityType,
  mainIndustry,
  industry,
  phone,
  website,
  businessDescription,
  averageProductMargin,
  monthlyOnlineRevenue,
  referralSource,
  onSubmit,
  isSubmitting,
  error,
  onNavigateBack,
  referrerBorrowerName,
}) => {
  const monthlyOnlineRevenueValue = monthlyOnlineRevenueOptions.find(
    (option) => option.name === monthlyOnlineRevenue
  )?.value;
  const form = useForm({
    defaultValues: {
      legalBusinessName: name,
      displayName,
      entityType,
      mainIndustry,
      industry,
      phone,
      website,
      businessDescription,
      hasWebsiteValue: (!!website && 'true') || (!!businessDescription && 'false') || undefined,
      averageProductMargin,
      monthlyOnlineRevenue: monthlyOnlineRevenueValue,
      referralSource,
    },
  });
  const { register, handleSubmit, setError, watch } = form;

  const validateProductMargin = (averageProductMargin) => {
    const productMargin = parseInt(averageProductMargin);
    if (productMargin <= 100 && productMargin > 0) return true;

    const message =
      productMargin > 100
        ? 'Product margin must be less than 100%'
        : 'Product margin must be equal to or greater than 1%';
    setError('averageProductMargin', { type: 'manual', message });

    return false;
  };

  const handleSubmitForm = async (data) => {
    if (!data.monthlyOnlineRevenue) {
      data.monthlyOnlineRevenue = monthlyOnlineRevenueValue;
    }
    if (validateProductMargin(data.averageProductMargin)) {
      onSubmit(data);
    }
  };

  const [selectedMainIndustry, setSelectedMainIndustry] = useState(mainIndustry);
  const industryOptions = selectedMainIndustry
    ? industries.filter((industry) => industry.groupIds.includes(selectedMainIndustry))
    : [];

  const hasWebsiteValue = watch('hasWebsiteValue');

  return (
    <div className="tw-max-w-screen-sm">
      <h1 className="tw-mt-12">{ONBOARDING_STEPS.businessDetails.title}</h1>
      <p className="tw-mb-12">Start your account set up with some information about your business</p>

      {referrerBorrowerName && (
        <div
          data-testid="registration-referral"
          className="tw-border tw-border-neutral-grey-3 tw-rounded-md tw-flex tw-items-center tw-justify-between tw-mb-12 tw-text-sm tw-text-neutral-grey-1 tw-py-2 tw-px-1"
        >
          <div>
            <GiftBoxWithCoin />
          </div>
          <div>
            You registered using <span className="tw-font-bold">{referrerBorrowerName}</span>'s referral code. Earn
            10,000 points after completing your first transaction.{' '}
            <Link to={{ pathname: config.baseLegalUrl }} target="_blank" rel="noreferrer">
              <span className="tw-font-bold tw-underline hover:tw-text-primary-dark-green">See Terms</span>
            </Link>
          </div>
        </div>
      )}

      <div>
        <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <TextField
              rootClass="tw-mb-8"
              name="legalBusinessName"
              label="Your Legal Business Name"
              placeholder="12345678 Canada Inc."
              required
              ref={register({ required: true })}
            />

            <div className="tw-mb-8 tw-relative">
              <TextField
                name="displayName"
                placeholder={`e.g. "Lannister Forks"`}
                label="Operating Name"
                ref={register()}
              />
              <InfoTip icon={<InfoTip1Icon />} text="Enter the name that you do business with your customers as" />
            </div>

            <Select
              rootClass="tw-mb-8"
              name="entityType"
              label="Legal Business Structure"
              placeholder="Select Option"
              required
              ref={register({ required: true })}
              options={entityTypesOptions}
            />
            <Select
              rootClass="tw-mb-8"
              name="mainIndustry"
              label="Industry"
              placeholder="Select Option"
              required
              ref={register({ required: true })}
              options={mainIndustries}
              onChange={(e) => {
                setSelectedMainIndustry(e.target.value);
              }}
            />
            {selectedMainIndustry && (
              <Select
                rootClass="tw-mb-8"
                name="industry"
                label="Sub-Industry"
                placeholder="Select Option"
                required
                ref={register({ required: true })}
                options={industryOptions}
                infoText="If you sell products in multiple industries, select the industry that encompasses the largest share of your revenue."
              />
            )}

            <div className="tw-mb-8">
              <label className="tw-text-neutral-grey-1 tw-mb-4" htmlFor="hasWebsiteValue">
                Do you have a business website?
              </label>
              <RadioField
                className="tw-overflow-hidden"
                label="Do you have a business website?"
                name="hasWebsiteValue"
                options={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' },
                ]}
                ref={register({ required: true })}
              />
            </div>
            {hasWebsiteValue === 'true' && (
              <TextField
                rootClass="tw-mb-8"
                name="website"
                label="Business Website"
                infoText="If you sell on a marketplace, include the link to your seller page."
                placeholder="https://joecompany.com"
                required
                ref={register({
                  required: !businessDescription ? true : false,
                  pattern: {
                    value: /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/,
                    message: 'Invalid url',
                  },
                })}
              />
            )}
            {hasWebsiteValue === 'false' && (
              <TextArea
                rootClass="tw-mb-8"
                name="businessDescription"
                label="Explain what your business does"
                required
                ref={register({ required: !website ? true : false })}
              />
            )}

            {!monthlyOnlineRevenueValue && (
              <Select
                rootClass="tw-mb-8"
                name="monthlyOnlineRevenue"
                label="What is your Average Monthly Business Revenue?"
                placeholder="Select Option"
                required
                ref={register({ required: true })}
                options={monthlyOnlineRevenueOptions}
              />
            )}

            <div className="tw-mb-8 tw-relative">
              <MaskedTextField
                name="averageProductMargin"
                placeholder="53%"
                label="Average Product Margin"
                rules={register({ required: true })}
                suffix="%"
                allowNegative={false}
                isNumericString={true}
                isAllowed={validateInteger}
                required
              />
              <InfoTip
                icon={<InfoTip2Icon />}
                text={
                  <>
                    Product margin is the average price of your product minus the average cost of your product. E.g. if
                    you sell products for $100 and it costs you $60 <b>to get the product to your customer</b>, your
                    product margin would be 40% - ($100 - $60)/$100 = 40%.
                  </>
                }
              />
            </div>
            <PhoneField
              rootClass="tw-mb-8"
              name="phone"
              label="Business Phone Number"
              autoComplete="tel-national"
              rules={{ required: true }}
              required
              infoText="If your business uses a toll-free number, we require an alternate non-toll free Canadian number."
            />
            <Select
              rootClass="tw-mb-8"
              name="referralSource"
              label="How did you hear about Loop?"
              placeholder="Select Option"
              required
              ref={register({ required: true })}
              options={referralSourceOptions}
            />

            <div className="tw-flex tw-justify-between tw-mt-16">
              <Button
                type="button"
                secondary
                className="tw-w-max tw-bg-neutral-grey-5 tw-text-neutral-dark"
                onClick={onNavigateBack}
              >
                Back
              </Button>
              <SubmitButton className="tw-w-max" isDisabled={isSubmitting}>
                <div className="tw-flex tw-justify-center tw-items-center">
                  <p className="tw-text-neutral-light tw-mr-2">{isSubmitting ? 'Submitting...' : 'Continue'}</p>
                  <ArrowRight />
                </div>
              </SubmitButton>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

const Content = (props) => {
  const history = useHistory();
  const [submitBusinessDetail, { loading: isSubmitting, error }] = useMutation(SUBMIT_BUSINESS_DETAIL);
  const onGoToSalesChannels = () => history.push('/onboarding/eligibility/start');

  const onSubmit = async (data) => {
    try {
      await submitBusinessDetail({
        variables: {
          ...data,
          averageProductMargin: parseFloat(data.averageProductMargin),
        },
      });
      ampTrackEvent('onboarding: business_details: success');
      history.push('/onboarding/business-address');
    } catch (err) {
      console.error(err);
      ampTrackEvent('onboarding: business_details: error');
    }
  };

  return (
    <Form
      onNavigateBack={onGoToSalesChannels}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      error={error}
      {...props}
    />
  );
};

const BusinessDetails = (props) => {
  const { loading, ...otherProps } = props;
  useSetPageTitle(ONBOARDING_STEPS.businessDetails.label);
  const { loading: isLoadingDetails } = useOnboardingRedirect();

  return (
    <Layout>
      <Progress currentStep={2} />
      {isLoadingDetails || loading ? <Loading /> : <Content {...otherProps} />}
    </Layout>
  );
};

export default BusinessDetails;
