import React from 'react';

import { useToggle } from 'hooks';
import SettingsShoppingBag from 'components/svg/SettingsShoppingBag';
import PermissionChecker from 'components/PermissionChecker';
import Button from 'components/Button';
import { SCOPE } from 'constants/index';
import { ExternalAccounts, EmptyState } from 'components/settings/Integrations/components';
import { IntegrationsModal } from 'components/settings/Integrations/components/Modals';
import InfoBlock from 'components/settings/InfoBlock';

const SalesChannelSection = ({ externalAccounts, reload }) => {
  const { isOpen: showIntegrationsModal, open: onShowIntegrationsModal, close: onHideIntegrationsModal } = useToggle();

  return (
    <div className="tw-mb-16 tw-px-4 tw-pb-8">
      <div className="tw-flex tw-flex-col">
        <div className="tw-pb-4 tw-flex tw-flex-row tw-justify-between">
          <h5>Sales Channels ({externalAccounts?.length})</h5>
          <div data-testid="container">
            <PermissionChecker scope={SCOPE.manageExternalAccounts}>
              <Button primary className="tw-w-max" onClick={onShowIntegrationsModal}>
                Connect Channel
              </Button>
            </PermissionChecker>
          </div>
        </div>

        <div className="tw-mt-4 tw-mb-8">
          <InfoBlock
            icon={<SettingsShoppingBag className="tw-w-full tw-h-full tw-relative" />}
            content={
              'Your credit limit is based off of your sales processed through sales channels. Connecting more sales will increase your credit limit over time.'
            }
          />
        </div>
        <div className="tw-min-h-full tw-flex tw-flex-row">
          {externalAccounts?.length > 0 ? (
            <ExternalAccounts accounts={externalAccounts} reload={reload} />
          ) : (
            <EmptyState />
          )}
        </div>
      </div>
      <IntegrationsModal
        show={showIntegrationsModal}
        reload={reload}
        onClose={onHideIntegrationsModal}
        externalAccounts={externalAccounts}
      />
    </div>
  );
};

export default SalesChannelSection;
