import { useState, useCallback, useContext, useMemo, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { AccountsContext } from 'context/Accounts';
import { useToggle, useUpdateApolloCache } from 'hooks';
import { WALLET_ACTIVE_EXTERNAL_ACCOUNT_FRAGMENT, GET_CORE_EXTERNAL_ACCOUNTS } from 'graphql/wallets';
import { useExternalAccountAgreements } from 'components/wallets/OpenCADAccountModal/hooks';
import { CAD_ONBOARDING_STEPS } from '../constants';
import { OpenCADAccountModalProps } from '../OpenCADAccountModal.types';
import { CADAgreementDocumentType, USDAgreementDocumentType, AgreementStatus } from 'types/coreExternalAccount';
import { AccountWalletStatus, AccountWallet } from 'types/wallet';

const useOpenCADAccountModal = ({
  onAddAccount,
  onClose,
}: Pick<OpenCADAccountModalProps, 'onAddAccount' | 'onClose'>) => {
  const { selectedWalletId, wallets } = useContext(AccountsContext);

  const selectedWallet = useMemo(
    () => wallets.find((wallet) => wallet.id === selectedWalletId),
    [wallets, selectedWalletId]
  );
  const selectedWalletStatus = selectedWallet?.status;
  const externalWalletAccountId = selectedWallet?.externalAccounts?.[0]?.id;

  const [step, setStep] = useState(CAD_ONBOARDING_STEPS.Details[0]);

  const { isOpen: isFullScreen, open: enableFullScreen, close: disableFullScreen } = useToggle();

  const isCompleted = step === CAD_ONBOARDING_STEPS.Complete[0];

  const {
    agreements,
    loading: agreementsLoading,
    error: agreementsError,
  } = useExternalAccountAgreements(externalWalletAccountId);

  const [getCoreExternalAccounts] = useLazyQuery<{
    wallets: Pick<AccountWallet, 'id' | 'currency' | 'activeExternalAccount' | 'externalAccounts'>[];
  }>(GET_CORE_EXTERNAL_ACCOUNTS, { fetchPolicy: 'network-only' });

  const { updateCache } = useUpdateApolloCache();

  const handleFinish = useCallback(() => {
    if (isCompleted) {
      if (selectedWallet) {
        const updatedWalletData = {
          id: selectedWallet.id,
          __typename: 'LedgerAccount',
          activeExternalAccount: true,
        };

        updateCache({
          entity: selectedWallet,
          fragment: WALLET_ACTIVE_EXTERNAL_ACCOUNT_FRAGMENT,
          data: updatedWalletData,
        });
      }
    }

    getCoreExternalAccounts();
    onAddAccount?.();
    onClose();
  }, [onAddAccount, onClose, isCompleted, selectedWallet, updateCache, getCoreExternalAccounts]);

  useEffect(() => {
    if (!agreements.length || selectedWalletStatus === AccountWalletStatus.active) return;

    const CATaxAgreement = agreements.find(({ documentType }) =>
      Object.values(CADAgreementDocumentType).includes(documentType as CADAgreementDocumentType)
    );

    if (CATaxAgreement && CATaxAgreement.status !== AgreementStatus.signed) {
      setStep(CAD_ONBOARDING_STEPS.Agreements[0]);
      return;
    }

    const USTaxAgreement = agreements.find(({ documentType }) =>
      Object.values(USDAgreementDocumentType).includes(documentType as USDAgreementDocumentType)
    );

    if (USTaxAgreement && USTaxAgreement.status !== AgreementStatus.signed) {
      setStep(CAD_ONBOARDING_STEPS.Agreements[1]);
      return;
    }

    // assuming that the only step is missing is internal agreements
    setStep(CAD_ONBOARDING_STEPS.Agreements[2]);
  }, [selectedWalletStatus, agreements]);

  return {
    step,
    setStep,
    isFullScreen,
    enableFullScreen,
    disableFullScreen,
    isCompleted,
    handleFinish,
    isLoading: agreementsLoading,
    isError: !!agreementsError,
    externalWalletAccountId,
  };
};

export default useOpenCADAccountModal;
