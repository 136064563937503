import { BankAccountCurrency } from './bankAccount';

export enum PaymentRequestCurrency {
  USD = BankAccountCurrency.USD,
  CAD = BankAccountCurrency.CAD,
}

export type Payor = {
  id: string;
  name: string;
  email: string;
  referenceId?: string;
};

export type PayorPaymentRequestVendor = {
  name: string;
  email: string;
  address: {
    country: string;
    countrySubdivision: string;
    city: string;
    street: string;
    unitNumber?: string;
    postalCode: string;
  };
};

export enum PaymentRequestStatus {
  pending = 'pending',
  scheduled = 'scheduled',
  rejected = 'rejected',
  completed = 'completed',
  failed = 'failed',
  cancelled = 'cancelled',
  expired = 'expired',
  processing = 'processing',
  flagged_for_review = 'flagged_for_review',
  settled = 'settled',
  overdue = 'overdue',
  held = 'held',
}

export enum PaymentRequestScheduleFrequency {
  once = 'once',
  weekly = 'weekly',
  bi_weekly = 'bi_weekly',
  month_end = 'month_end',
  monthly = 'monthly',
  semi_monthly = 'semi_monthly',
  quarterly = 'quarterly',
}

export type PaymentRequestSchedule = {
  frequency: PaymentRequestScheduleFrequency;
  startDate: Date;
  endDate?: Date;
  numberOfOccurrences?: number;
};

export type PaymentRequest = {
  id: string;
  payor: {
    name: string;
  };
  account: {
    name: string;
  };
  amount: {
    amount: number;
    currency: PaymentRequestCurrency;
  };
  createdAt: Date;
  dueDate: Date;
  status: PaymentRequestStatus;
  invoiceNumber?: string;
  vendorName: string;
  fee: {
    amount: number;
    currency: PaymentRequestCurrency;
  };
  schedule: PaymentRequestSchedule | null;
};

export type PayorUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  legalBusinessName: string;
  preAuthorizationForms: {
    bankAccount: {
      id: string;
      currency: PaymentRequestCurrency;
    };
    version: string;
  }[];
};

export type PayorPaymentRequest = {
  id: string;
  payor: {
    id: string;
    email: string;
  };
  vendor: Pick<PayorPaymentRequestVendor, 'name'> & Partial<PayorPaymentRequestVendor>;
  amount: {
    amount: number;
    currency: PaymentRequestCurrency;
  };
  createdAt: Date;
  dueDate: Date;
  status: PaymentRequestStatus;
  invoiceNumber?: string;
  schedule: PaymentRequestSchedule | null;
};

export type PayorBankAccount = {
  id: string;
  bankName: string;
  accountNumber: string;
  routingNumber?: string;
  institutionNumber?: string;
  transitNumber?: string;
  maskedAccountNumber: string;
  currency: PaymentRequestCurrency;
  country: string;
};
