import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Popover, Transition } from '@headlessui/react';
import { BiCheckShield, BiWrench } from 'react-icons/bi';
import { toast } from 'react-toastify';
import ArrowRight from 'components/svg/ArrowRight';
import { useToggle } from 'hooks';
import CanadaFlag from 'components/svg/flags/ca_round';
import USFlag from 'components/svg/flags/us_round';
import EUFlag from 'components/svg/flags/eu_round';
import GBFlag from 'components/svg/flags/gb_round';
import {
  GET_BANK_ACCOUNTS,
  SET_DEFAULT_BANK_ACCOUNT,
  ANSWER_FLINKS_MFA_QUESTIONS,
  DELETE_BANK_ACCOUNT,
} from 'graphql/integrations';
import { AuthContext } from 'context/Auth';
import { Loaders } from 'components/cards/Loader';
import SettingsLayout from 'containers/settings/SettingsLayout';
import Modals from './bankAccounts/Modals';
import { defaultBankImage, bankImages, SCOPE } from 'constants/index';
import { SettingsContext } from 'context/Settings';
import Add from 'components/svg/Add';
import RemoveIcon from 'components/svg/RemoveIcon';
import CardAndCoins from 'components/svg/CardAndCoins2';
import RepeatOutline from 'components/svg/RepeatOutline';
import StarOutline from 'components/svg/StarOutline';
import LockClosedOutline from 'components/svg/LockClosedOutline';
import DocumentAttachOutline from 'components/svg/DocumentAttachOutline';
import Ellipses from 'components/svg/Ellipses';
import Button from 'components/Button';
import PermissionChecker from 'components/PermissionChecker';
import BankAccountBanner from './bankAccounts/BankAccountBanner';
import BankAccountErrorBanner from './bankAccounts/BankAccountErrorBanner';
import { ThemeContext } from 'context/ThemeContext';

const flagComponentMap = (currency) =>
  ({
    CAD: CanadaFlag,
    USD: USFlag,
    EUR: EUFlag,
    GBP: GBFlag,
  }[currency] || CanadaFlag);

const CurrencyFlag = ({ currency }) => {
  const FlagComponent = flagComponentMap(currency);

  return (
    <div className="d-flex flex-column justify-content-center">
      <FlagComponent size={18} />
    </div>
  );
};

const RowActions = ({
  id,
  verified,
  reconnectionRequired,
  reload,
  onReconnect,
  connected,
  mfaQuestions,
  mfaAnswersRequired,
  institutionName,
  default: isDefault,
}) => {
  const history = useHistory();

  const [setDefaultBankAccount, { loading: isSubmitting }] = useMutation(SET_DEFAULT_BANK_ACCOUNT, {
    refetchQueries: [{ query: GET_BANK_ACCOUNTS }],
    awaitRefetchQueries: true,
  });
  const [disconnectBankAccount, { loading: isDisconnecting }] = useMutation(DELETE_BANK_ACCOUNT, {
    refetchQueries: [{ query: GET_BANK_ACCOUNTS }],
    awaitRefetchQueries: true,
  });
  const [answerMFAQuestions, { loading: isAnswering }] = useMutation(ANSWER_FLINKS_MFA_QUESTIONS, {
    refetchQueries: [{ query: GET_BANK_ACCOUNTS, fetchPolicy: 'network-only' }],
    awaitRefetchQueries: true,
  });

  const { isOpen: isOpenAnswerMFAModal, open: openAnswerMFAModal, close: closeAnswerMFAModal } = useToggle();
  const { isOpen: isOpenDisconnectModal, open: openDisconnectModal, close: closeDisconnectModal } = useToggle();

  const setDefaultAccount = async (id) => {
    try {
      await setDefaultBankAccount({ variables: { bankAccountId: id } });
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong. Please try again later');
    }
  };

  const disconnectAccount = async () => {
    try {
      await disconnectBankAccount({ variables: { bankAccountId: id } });
      toast.success('Bank account was successfully disconnected');
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong. Please try again later');
    }
  };

  const reconnectBankAccount = () => {
    onReconnect();
  };

  const handleUploadDocuments = () => {
    history.push('/dashboard/settings/documents');
  };

  const registerSecurityQuestions = async (answers) => {
    try {
      const result = await answerMFAQuestions({ variables: { bankAccount: id, answers: answers } });
      if (result.data) {
        if (result.data.answerFlinksMfaQuestions.reconnectionRequired) {
          toast.error('Unable to reconnect using given answers');
        } else {
          toast.success('Account was reconnected successfully');
        }
      }
    } catch (error) {
      console.error(error);
      toast.error('Unable to register security questions');
    }
  };

  const onHideAnswerMFAModal = () => {
    reload();
    closeAnswerMFAModal();
  };

  const hideBankAccountOptions = isDefault && connected && verified && !reconnectionRequired;
  const menuIcon = mfaAnswersRequired ? <BiWrench /> : <Ellipses />;

  return (
    <div>
      <Popover className="tw-relative">
        {({ close }) => (
          <>
            <PermissionChecker scope={SCOPE.manageBankAccounts}>
              {!hideBankAccountOptions && (
                <Popover.Button>
                  <div role="popover" className="tw-flex tw-justify-center tw-w-9">
                    {menuIcon}
                  </div>
                </Popover.Button>
              )}
            </PermissionChecker>
            <Transition
              unmount={true}
              leave="tw-transition tw-ease-in tw-duration-100"
              leaveFrom="tw-opacity-100"
              leaveTo="tw-opacity-0"
            >
              <Popover.Panel className="tw-absolute tw-z-40 tw-right-0 tw-w-72 tw-p-2 tw-bg-neutral-light tw-rounded-md tw-shadow-notification">
                <div className="tw-flex tw-flex-col">
                  <div className="tw-flex tw-flex-col tw-bg-white">
                    {mfaAnswersRequired && (
                      <Button
                        className="tw-bg-white hover:tw-bg-neutral-grey-4 tw-text-xs tw-text-neutral-grey-1 tw-py-2 tw-px-4 tw-shadow"
                        onClick={openAnswerMFAModal}
                        disabled={isAnswering}
                      >
                        <div className="tw-flex tw-flex-row tw-items-center tw-font-semibold tw-text-primary-dark-green">
                          <BiCheckShield size="16" className="mr-1" />
                          {isAnswering ? (
                            <p className="tw-ml-2 tw-text-primary-dark-green">Registering...</p>
                          ) : (
                            <div className="tw-ml-2 tw-flex tw-flex-col tw-items-start">
                              <span className="tw-text-primary-dark-green tw-text-base">Answer Security Questions</span>
                              <span className="tw-text-primary-dark-green tw-text-sm">(Recommended)</span>
                            </div>
                          )}
                        </div>
                      </Button>
                    )}
                    {!isDefault && verified ? (
                      <Button
                        className="tw-bg-white hover:tw-bg-neutral-grey-4 tw-text-xs tw-text-neutral-grey-1 tw-py-2 tw-px-4 tw-shadow"
                        onClick={async () => {
                          await setDefaultAccount(id);
                          close();
                        }}
                        disabled={isAnswering}
                      >
                        <div className="tw-flex tw-flex-row tw-items-center tw-text-neutral-grey-1">
                          <StarOutline height="16" width="16" stroke="grey" />
                          <p className="tw-ml-2 tw-text-neutral-grey-1">
                            {isSubmitting ? 'Updating...' : `Make Default Bank Account`}
                          </p>
                        </div>
                      </Button>
                    ) : (
                      <div className="tw-flex tw-justify-center tw-bg-secondary-light-yellow tw-rounded-sm">
                        <small className="tw-rounded-lg tw-text-primary-yellow">
                          Default Bank Accounts must be verified
                        </small>
                      </div>
                    )}
                    {!verified && (
                      <Button
                        className="tw-bg-white hover:tw-bg-neutral-grey-4 tw-text-xs tw-text-neutral-grey-1 tw-py-2 tw-px-4 tw-shadow"
                        onClick={handleUploadDocuments}
                      >
                        <div className="tw-flex tw-flex-row tw-items-center tw-text-neutral-grey-1">
                          <DocumentAttachOutline size="16" />
                          <p className="tw-ml-2 tw-text-neutral-grey-1">Upload Document to Verify</p>
                        </div>
                      </Button>
                    )}
                    {reconnectionRequired && (
                      <Button
                        className="tw-bg-white hover:tw-bg-neutral-grey-4 tw-text-xs tw-text-neutral-grey-1 tw-py-2 tw-px-4 tw-shadow"
                        onClick={reconnectBankAccount}
                      >
                        <div className="tw-flex tw-flex-row tw-items-center tw-text-neutral-grey-1">
                          <RepeatOutline size="16" className="mr-1" />
                          <p className="tw-ml-2 tw-text-neutral-grey-1">Reconnect</p>
                        </div>
                      </Button>
                    )}
                    {connected && (
                      <Button
                        className="tw-bg-white hover:tw-bg-neutral-grey-4 tw-text-xs tw-text-neutral-grey-1 tw-py-2 tw-px-4 tw-shadow"
                        onClick={openDisconnectModal}
                        disabled={isAnswering}
                      >
                        <div className="tw-flex tw-flex-row tw-items-center">
                          <RemoveIcon className="tw-text-semantic-error" height="16" width="16" />
                          <p className="tw-ml-2 tw-text-semantic-error">
                            {isDisconnecting ? 'Disconnecting...' : `Disconnect`}
                          </p>
                        </div>
                      </Button>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <Modals.Disconnect
        show={isOpenDisconnectModal}
        onClose={closeDisconnectModal}
        onDisconnect={disconnectAccount}
        reload={reload}
      />
      <Modals.AnswerMFA
        show={isOpenAnswerMFAModal}
        onClose={onHideAnswerMFAModal}
        reload={reload}
        onAnswerMFA={registerSecurityQuestions}
        mfaQuestions={mfaQuestions}
        isAnswering={isAnswering}
        institutionName={institutionName}
      />
    </div>
  );
};

const BankLogoComponent = ({ institutionName }) => {
  const bankImage = bankImages.find((b) => b.name === institutionName);
  const imageUrl = bankImage ? bankImage.image : defaultBankImage;
  return (
    <div
      className="tw-w-full tw-h-16 tw-rounded-md tw-self-center tw-bg-center tw-bg-cover"
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
    />
  );
};

const BankConnectionRow = (props) => {
  const {
    id,
    institutionName,
    maskedNumber,
    currency,
    verified,
    connected,
    reconnectionRequired,
    defaultAccount,
    depositVerificationStatus,
  } = props;
  const [depositVerificationModalVisible, setDepositVerificationModalVisible] = useState(false);

  const { isDarkTheme } = useContext(ThemeContext);

  useEffect(() => {
    requiresDepositVerification(depositVerificationStatus, verified);
  }, [depositVerificationStatus, verified]);

  const requiresDepositVerification = (depositVerificationStatus, verified) => {
    if (['waiting_verification', 'disabled'].includes(depositVerificationStatus) && !verified) {
      setDepositVerificationModalVisible(true);
    } else {
      setDepositVerificationModalVisible(false);
    }
  };
  const {
    isOpen: isDepositVerificationModal,
    open: openDepositVerificationModal,
    close: closeDepositVerificationModal,
  } = useToggle();
  const borderRadius = defaultAccount ? 'tw-rounded-bl-md tw-rounded-br-md' : 'tw-rounded-md';

  const accountLabel = (verified && (
    <span className="tw-bg-secondary-light-green tw-rounded-lg tw-text-semantic-success tw-flex tw-flex-shrink-1 tw-px-2 tw-py-1 tw-w-min">
      Verified
    </span>
  )) || (
    <span className="tw-bg-secondary-light-yellow tw-rounded-lg tw-text-primary-yellow tw-flex tw-justify-center tw-flex-shrink-1 tw-px-2 tw-py-1 tw-w-fit">
      Needs verification
    </span>
  );

  return (
    <div className="tw-px-2 tw-flex tw-flex-col tw-self-end tw-w-1/4 tw-min-w-max">
      {defaultAccount ? (
        <div
          className={`tw-flex tw-justify-center tw-rounded-tr-lg tw-rounded-tl-lg tw-items-center tw-py-1 ${
            isDarkTheme ? 'tw-text-neutral-dark tw-bg-primary-light-green' : 'tw-text-neutral-light tw-bg-primary-lilac'
          }`}
        >
          <StarOutline />
          <span className="tw-pl-2">Default Account</span>
        </div>
      ) : (
        <div />
      )}
      <div
        className={`tw-flex tw-flex-col tw-border tw-border-rounded-br-lg tw-border-neutral-grey-3 tw-h-96 tw-justify-between ${borderRadius}`}
      >
        <div className="tw-flex tw-flex-col tw-p-5">
          <BankLogoComponent institutionName={institutionName} />
          <div className="tw-my-5">{accountLabel}</div>
          <div className="tw-flex tw-flex-col tw-text-neutral-grey-2 ">
            <span>{institutionName}</span>
            <span>Account ending in {maskedNumber}</span>
          </div>
          <div className="tw-flex tw-flex-row tw-items-center tw-my-4">
            {connected && !reconnectionRequired && (
              <div className="tw-text-semantic-success tw-flex tw-flex-shrink-1">
                <div className="tw-self-center tw-mr-2">
                  <LockClosedOutline />
                </div>
                <span>Securely Connected</span>
              </div>
            )}
          </div>
          {depositVerificationModalVisible && (
            <div className="tw-py-4">
              <Button
                onClick={openDepositVerificationModal}
                className="tw-bg-primary-dark-green tw-text-neutral-light tw-rounded-md tw-py-2 tw-px-4 tw-flex tw-flex-row tw-items-center"
              >
                <span className="tw-pr-2">Verify Bank Account</span>
                <ArrowRight />
              </Button>
            </div>
          )}
        </div>
        <div className="tw-flex tw-flex-row tw-justify-between tw-bg-neutral-grey-4 tw-rounded-bl-md tw-rounded-br-md tw-p-4">
          <div className="tw-flex tw-flex-row tw-items-center">
            <CurrencyFlag currency={currency} />
            <span className="tw-ml-4">{currency}</span>
          </div>
          <RowActions {...props} />
        </div>
      </div>
      <Modals.DepositVerification
        show={isDepositVerificationModal}
        onClose={closeDepositVerificationModal}
        currency={currency}
        id={id}
        depositVerificationStatus={depositVerificationStatus}
      />
    </div>
  );
};

const BankAccountSettings = ({ bankAccounts, allowBankAccountCreation, bankConnectionError, error, reload }) => {
  const [showAddConnectionModal, setShowAddConnectionModal] = useState(false);
  const onShowAddConnectionModal = () => {
    setShowAddConnectionModal(true);
  };
  const onHideAddConnectionModal = () => {
    reload();
    setShowAddConnectionModal(false);
  };

  const reconnectionRequired =
    bankAccounts && bankAccounts.map((account) => account.reconnectionRequired).some((x) => x === true);
  const messageFrame = !reconnectionRequired && !bankConnectionError && <BankAccountBanner />;
  const errorBannerFrame = bankConnectionError && <BankAccountErrorBanner />;

  return (
    <div className="tw-w-90 tw-px-4 tw-py-8">
      {error && (
        <div className="tw-container-md tw-flex-tw-flex-col">
          <div className="tw-pb-8 tw-flex tw-flex-row tw-justify-between">
            <h5 className="tw-text-lg tw-w-3/6">{`Bank Accounts (0)`}</h5>
          </div>
          <div className="tw-flex tw-flex-col tw-items-center tw-m-4">
            <span className="tw-py-4 tw-text-neutral-grey-1">
              We are retrieving the details of the account you just connected. Refresh or click the Reload button to see
              if your account has been connected.
            </span>
            <PermissionChecker scope={SCOPE.manageBankAccounts}>
              <div className="tw-py-4">
                <Button
                  onClick={() => window.location.reload()}
                  className="tw-bg-primary-dark-green tw-text-neutral-light tw-rounded-md tw-py-3 tw-px-4 tw-flex tw-flex-row tw-items-center"
                >
                  <span>Reload</span>
                </Button>
              </div>
            </PermissionChecker>
          </div>
        </div>
      )}

      {!error &&
        (bankAccounts?.length > 0 ? (
          <div className="tw-container-md tw-flex-tw-flex-col">
            {errorBannerFrame}
            <div className="tw-pb-8 tw-flex tw-flex-row tw-justify-between">
              <span className="tw-text-lg tw-w-3/6">{`Bank Accounts (${bankAccounts.length})`}</span>
              <PermissionChecker scope={SCOPE.manageBankAccounts}>
                <div>
                  <Button
                    onClick={onShowAddConnectionModal}
                    className="tw-bg-primary-dark-green tw-text-neutral-light tw-rounded-md tw-pl-4 tw-flex tw-flex-row tw-items-center"
                  >
                    <Add className="tw-mr-2" />
                    <span className="tw-pr-2">Add Bank Account</span>
                  </Button>
                </div>
              </PermissionChecker>
            </div>

            {messageFrame}

            <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-y-8 tw-justify-start tw-my-4">
              {bankAccounts?.map(
                ({
                  id,
                  institutionName,
                  maskedNumber,
                  currency,
                  verified,
                  connected,
                  reconnectionRequired,
                  default: defaultAccount,
                  depositVerificationStatus,
                }) => (
                  <BankConnectionRow
                    key={id}
                    onReconnect={onShowAddConnectionModal}
                    reload={reload}
                    id={id}
                    institutionName={institutionName}
                    maskedNumber={maskedNumber}
                    currency={currency}
                    verified={verified}
                    connected={connected}
                    reconnectionRequired={reconnectionRequired}
                    defaultAccount={defaultAccount}
                    depositVerificationStatus={depositVerificationStatus}
                  />
                )
              )}
            </div>
          </div>
        ) : (
          <div className="tw-container-md tw-flex-tw-flex-col">
            {errorBannerFrame}
            <div className="tw-pb-8 tw-flex tw-flex-row tw-justify-between">
              <span className="tw-text-lg tw-w-3/6">{`Bank Accounts (0)`}</span>
            </div>
            <div className="tw-flex tw-flex-col tw-items-center tw-m-4">
              <div className="tw-py-4">
                <CardAndCoins />
              </div>
              <span className="tw-py-4 tw-text-neutral-grey-1">
                Add your first business bank account to create cards and start spending.
              </span>
              <PermissionChecker scope={SCOPE.manageBankAccounts}>
                <Button
                  onClick={onShowAddConnectionModal}
                  className="tw-bg-primary-dark-green tw-text-neutral-light tw-rounded-md tw-py-3 tw-px-4 tw-flex tw-flex-row tw-items-center"
                >
                  <span className="tw-pr-2">Add Bank Account</span>
                  <Add size="16" />
                </Button>
              </PermissionChecker>
            </div>
          </div>
        ))}
      <Modals.AddConnection
        show={showAddConnectionModal}
        onClose={onHideAddConnectionModal}
        allowBankAccountCreation={allowBankAccountCreation}
      />
    </div>
  );
};

const Content = ({ setNotification }) => {
  const { meLoading } = useContext(AuthContext);
  const {
    bankAccounts,
    loadingBankAccounts,
    allowBankAccountCreation,
    bankConnectionError,
    waitingForBankDetails,
    bankAccountError: error,
    refetchBankAccountsRef,
  } = useContext(SettingsContext);
  return (loadingBankAccounts || waitingForBankDetails || meLoading) && !error ? (
    <Loaders.Light />
  ) : (
    <BankAccountSettings
      bankAccounts={bankAccounts}
      allowBankAccountCreation={allowBankAccountCreation}
      bankConnectionError={bankConnectionError}
      error={error}
      reload={refetchBankAccountsRef.current}
      setNotification={setNotification}
    />
  );
};

const BankAccounts = () => {
  const [notification, setNotification] = React.useState(null);

  return (
    <div className="tw-container-md tw-flex tw-flex-col tw-justify-around tw-w-full">
      <SettingsLayout notification={notification}>
        <Content setNotification={setNotification} />
      </SettingsLayout>
    </div>
  );
};

export default BankAccounts;
