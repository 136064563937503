import React from 'react';

import { CardProductType } from 'components/creditCards/components/CreateCardModal/constants';
import AccountLinked from 'components/svg/AccountLinked';
import CardLinked from 'components/svg/CardLinked';

export const mapCardProductTypeOptions = Object.values(CardProductType).map((val) => {
  const optionLabel = (
    <div className="tw-flex tw-flex-row tw-gap-2 tw-mr-3 tw-items-center">
      {val === CardProductType.Credit ? <CardLinked /> : <AccountLinked />}
      {val === CardProductType.Credit ? 'Credit Card' : 'Loop Wallet'}
    </div>
  );
  return { label: optionLabel, value: val };
});
