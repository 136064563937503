import React from 'react';
import { Link } from 'react-router-dom';

import ActivateCardModal from 'components/creditCards/components/CardDetailsPage/components/CardDetails/components/CardActions/components/ActivateCardModal';
import Button from 'components/Button';
import { CardDetailsContextProvider } from 'components/creditCards/components/CardDetailsPage/CardDetailsContext';
import { CardsListItemActionProps } from './CardsListItemAction.types';
import { useCardAction } from './hooks';

const CardsListItemAction = ({ creditCardId, requiresActivation }: CardsListItemActionProps) => {
  const { showActivationModal, toggleActivationModal, handleCloseOnCardActivated } = useCardAction({
    creditCardId,
  });

  const buttonStyleClassName =
    'tw-bg-neutral-black tw-text-neutral-light tw-rounded-md tw-text-center tw-inline-flex tw-items-center tw-justify-center tw-py-2 tw-px-4 tw-font-bold hover:tw-text-neutral-light tw-text-sm md:tw-text-base';
  if (!requiresActivation) {
    return (
      <Link to={`/dashboard/cards/list/${creditCardId}`} className={buttonStyleClassName}>
        View Card
      </Link>
    );
  }

  return (
    <>
      <Button className={buttonStyleClassName} onClick={toggleActivationModal}>
        Activate Card
      </Button>
      {showActivationModal && creditCardId && (
        <CardDetailsContextProvider creditCardId={creditCardId}>
          <ActivateCardModal
            show={showActivationModal}
            onClose={toggleActivationModal}
            closeOnActivate={handleCloseOnCardActivated}
          />
        </CardDetailsContextProvider>
      )}
    </>
  );
};

export default CardsListItemAction;
