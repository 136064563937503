import React, { memo } from 'react';

import { QuickActions } from './components';
import { useHeader } from './hooks';
import { Loaders } from 'components/cards/Loader';
import IdentityVerification from 'components/IdentityVerification';
import { MESSAGES } from 'components/IdentityVerification/constants';
import { StatutoryBanner } from './components/StatutoryBanner/StatutoryBanner';

const Header = () => {
  const { meLoading, firstName } = useHeader();

  if (meLoading) return <Loaders.Spinner />;

  return (
    <>
      <div className="tw-flex tw-justify-between tw-w-full">
        <div className="tw-mr-16">
          <h2>Your Financial Dashboard</h2>
          <p>Welcome back, {firstName}!</p>
        </div>
        <QuickActions />
      </div>

      <IdentityVerification
        onlyIfRequested
        bannerMessage={MESSAGES.banner.continueUsingAccount}
        modalMessage={MESSAGES.modal.continueUsingAccount}
      />

      {/* <div className="tw-my-8">
        <GamificationBar />
      </div> */}
      <StatutoryBanner />
    </>
  );
};

export default memo(Header);
