import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { useGetUserInfo } from 'hooks';
import { GET_DOCUMENTS } from 'graphql/document';
import { GET_CONTACTS_NEED_CONSENT_AND_VERIFICATION } from 'graphql/onboarding';

const useHome = () => {
  const { loading: loadingDocumentData, data: documentData } = useQuery(GET_DOCUMENTS);
  const { loading: loadingContactData, data: contactData } = useQuery<{
    me: {
      account: {
        contactsNeedConsent: {
          creditCheckAuthorizationProvided: boolean;
          thirdPartyVerificationComplete: boolean;
        }[];
      };
    };
  }>(GET_CONTACTS_NEED_CONSENT_AND_VERIFICATION);

  const { isLoading, firstName, kycFailedReasons } = useGetUserInfo();

  const { documents: myDocuments } = documentData || {};
  const isMissingDocuments = !!kycFailedReasons.filter(
    (reason) => !myDocuments?.some((d: { category: string }) => d.category === reason)
  ).length;

  const contacts = get(contactData, 'me.account.contactsNeedConsent', []);
  const contactsMissingConsentOrVerification = !!contacts.filter(
    ({ creditCheckAuthorizationProvided, thirdPartyVerificationComplete }) =>
      !creditCheckAuthorizationProvided || !thirdPartyVerificationComplete
  ).length;

  const loading = isLoading || loadingDocumentData || loadingContactData;

  return {
    loading,
    firstName,
    isAccountPending: isMissingDocuments || contactsMissingConsentOrVerification,
  };
};

export default useHome;
