import React from 'react';
import qs from 'query-string';

import { validAccountingIntegrations } from 'components/settings/Integrations/constants';
import { ReferrerBanner, SalesChannelSection, CRASection } from 'components/settings/Integrations/components';

const IntegrationsSettings = ({ manageExternalAccounts, externalAccounts, craBusinessNumber, reload, location }) => {
  // TODO: Remove this once we are ready fro the CRA integration
  const showCRASection = false;
  const { referrer } = qs.parse(location && location.search);

  return (
    <div className="tw-container-md tw-flex tw-flex-col tw-justify-around tw-my-8">
      <ReferrerBanner referrer={referrer} />
      <div className="tw-container">
        <SalesChannelSection
          manageExternalAccounts={manageExternalAccounts}
          externalAccounts={externalAccounts.filter((ea) => !validAccountingIntegrations().includes(ea.name))}
          craBusinessNumber={craBusinessNumber}
          reload={reload}
        />
      </div>
      {showCRASection && (
        <div className="tw-container tw-pb-10">
          <CRASection craBusinessNumber={craBusinessNumber} />
        </div>
      )}
      <div className="tw-container" />
    </div>
  );
};

export default IntegrationsSettings;
