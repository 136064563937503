export enum CardType {
  Physical = 'Physical',
  Virtual = 'Virtual',
}

export enum CardProductType {
  Credit = 'CreditCard',
  LoopAccount = 'AccountLinkedCard',
}

export enum SettlementType {
  MultiCurrency = 'Multi-Currency',
  SingleCurrency = 'Single Currency',
}
