import React, { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FormProvider, useForm } from 'react-hook-form';
import { get } from 'lodash';
import { toast } from 'react-toastify';

import config from 'config';
import { ampTrackEvent } from 'amplitude';
import { SIGN_PRE_DEPOSIT_AUTHORIZATION } from 'graphql/bankAccounts';
import { GET_BUSINESS_ADDRESS } from 'graphql/user';
import { AuthContext } from 'context/Auth';
import { PaymentContext } from 'context/Payment';
import { Checkbox, SubmitButton } from 'components/FormFields/v2';
import { BankAccount, PADAgreementCAVersion } from 'types/bankAccount';
import { User } from 'types/user';
import { PADAgreementCAProps } from './PADAgreementCA.types';
import { CorePADAgreement } from './components';

const PADAgreementCA = ({
  onNextStep,
  loading,
  footerBorder = false,
  bankAccountWithoutPDA,
  submitButtonLabel = 'Submit Transfer',
  version,
}: PADAgreementCAProps) => {
  const form = useForm();
  const [signPreDepositAuthorization, { loading: loadingSignPDA }] = useMutation(SIGN_PRE_DEPOSIT_AUTHORIZATION);
  const { me } = useContext(AuthContext) as unknown as { me: User };
  const bankAccount = bankAccountWithoutPDA || useGetBankAccount();

  const { data: businessAddressData, loading: loadingBusinessAddress } = useQuery(GET_BUSINESS_ADDRESS);

  const { register, handleSubmit, watch } = form;
  const acceptedTerms = watch('acceptedTerms');
  const { firstName, lastName, email, account } = me || {};
  const businessAddress = get(businessAddressData, 'me.account', {});

  const handleAuthorizePDA = async () => {
    try {
      if (!bankAccount) throw new Error('External bank account is missing');
      const response = await signPreDepositAuthorization({
        variables: { firstName, lastName, bankAccountId: bankAccount.id, version },
      });

      if (!response?.data?.signPreDepositAuthorization) throw new Error('Failed to sign PDA');

      ampTrackEvent('addMoney: signPDA: success');
      onNextStep();
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const waitingServerResponse = loading || loadingSignPDA || loadingBusinessAddress;

  const isCoreAgreement = version === PADAgreementCAVersion.core;

  return (
    <>
      <div className="tw-px-8 tw-pt-8">
        <div className="tw-text-neutral-grey-2 tw-mb-4 tw-text-sm">Pre-authorized Debit Agreement</div>
        <div className="tw-overflow-y-scroll md:tw-h-96">
          {isCoreAgreement ? (
            <CorePADAgreement bankAccount={bankAccountWithoutPDA} />
          ) : (
            <>
              <div className="tw-mt-2">
                <PayorsInformation address={businessAddress?.address} email={email} name={account?.name} />
              </div>
              <div className="tw-mt-2">
                <DepositAccountInformation bankAccount={bankAccount} />
              </div>
              <div className="tw-mt-2">
                <AuthorizedSignerInformation firstName={firstName} lastName={lastName} />
              </div>
              <div className="tw-mt-2">
                <Agreement />
              </div>
            </>
          )}
        </div>
      </div>
      <FormProvider {...form}>
        <div className="tw-p-8">
          <Checkbox
            label="I accept the Pre-authorized Debit Agreement"
            name="acceptedTerms"
            ref={register({ required: true })}
            required
          />
        </div>
        <form onSubmit={handleSubmit(handleAuthorizePDA)}>
          <div
            className={`${
              footerBorder ? 'tw-border-t tw-border-neutral-grey-3' : ''
            } tw-px-8 tw-py-4 tw-flex tw-justify-end`}
          >
            <SubmitButton disabled={waitingServerResponse || !acceptedTerms}>
              {waitingServerResponse ? 'Submitting...' : submitButtonLabel}
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

// TODO: remove this functionality once '/dashboard/payments' page will be removed
// https://getloop.atlassian.net/browse/LBP-5813
const useGetBankAccount = () => {
  const { addMoneyInfo, moveMoneyInfo, payBalanceInfo } = useContext(PaymentContext) as unknown as {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addMoneyInfo: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    moveMoneyInfo: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payBalanceInfo: any;
  };

  if (addMoneyInfo) return addMoneyInfo.fromAccount;

  if (payBalanceInfo) return payBalanceInfo.fromAccount;

  return moveMoneyInfo?.toAccount;
};

const PayorsInformation = ({
  address,
  email,
  name,
}: {
  address: {
    street: string;
    postalCode: string;
    city: string;
    country: string;
    countrySubdivision: string;
  };
  email: string;
  name?: string;
}) => {
  const { street, city, countrySubdivision, country, postalCode } = address || {};

  return (
    <>
      <p>Payor's Information:</p>
      <Information label="Full Legal Name" value={`${name} (the "Payor")`} />
      <Information label="Street Address" value={street} />
      <Information label="City" value={city} />
      <Information label="Province" value={countrySubdivision} />
      <Information label="Postal Code" value={postalCode} />
      <Information label="Country" value={country} />
      <Information label="Email Address" value={email} />
    </>
  );
};

const DepositAccountInformation = ({ bankAccount }: { bankAccount: BankAccount }) => {
  if (!bankAccount) return null;
  const { institutionName, maskedNumber, institutionNumber, transitNumber } = bankAccount;
  const institutionNumberString = `${institutionNumber}`;
  const formattedInstitutionNumber =
    institutionNumberString.length > 3 ? institutionNumberString : ('00' + institutionNumberString).slice(-3);

  return (
    <>
      <p>Payor's Deposit Account Information:</p>
      <Information label={`Name of Bank (the "Processing Institution")`} value={institutionName} />
      <Information label="Branch Transit Number" value={transitNumber} />
      <Information label="Processing Institution Number" value={formattedInstitutionNumber} />
      <Information label="Deposit Account Number" value={maskedNumber} />
    </>
  );
};

const AuthorizedSignerInformation = ({ firstName, lastName }: { firstName?: string; lastName?: string }) => {
  return (
    <>
      <p>Authorized Signer's Information:</p>
      <Information label="Name" value={`${firstName} ${lastName} (the "Authorized Signer")`} />
    </>
  );
};

const Agreement = () => (
  <>
    <small>
      I, the Authorized Signer of the Payor, hereby authorize Loop Financial Inc. and any of its affiliates
      (collectively, “Loop”), and the Processing Institution (as defined hereinafter) or any other financial institution
      designated by Loop to debit from the Payor’s deposit account designated above (or any other account you may
      authorize at any time). This authorization shall remain valid until all of the Payor’s obligations under{' '}
      <a
        href={config.baseLegalUrl}
        className="tw-text-primary-blue tw-underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        Loop's Platform
      </a>{' '}
      and/or Loop’s product agreements, as amended or restated, will have been satisfied. For greater certainty, I
      hereby authorize Loop to debit from the Payor’s account from time to time at its sole discretion any payments and
      any other fee that may become payable thereunder, including any Late Payment Fee or NSF Fee (as such terms are
      defined in Loop’s Agreements). Amounts debited pursuant to this PAD Agreement may be fixed amounts or variable
      amounts.
    </small>
    <p className="tw-mt-4 tw-mb-1">Assignment:</p>
    <small>
      Loop may not assign this PAD Agreement, whether directly or indirectly, by operation of law, change of control or
      otherwise, without giving the Payor ten (10) business days' notice in writing.
    </small>
    <p className="tw-mt-4 tw-mb-1">Change of Account:</p>
    <small>
      In the event of a change in the information of the Payor’s deposit account, the Payor undertakes to notify Loop of
      such change in writing at least fifteen (15) business days before any scheduled payment and provide it with the
      information of its new deposit account.
    </small>
    <p className="tw-mt-4 tw-mb-1">Waiver to Pre-Notification:</p>
    <small>
      YOU HEREBY WAIVE ALL OF THE RIGHTS YOU MAY HAVE TO BE NOTIFIED PRIOR TO A DEBIT FROM YOUR DEPOSIT ACCOUNT FOR ANY
      REGULAR, VARIABLE OR SPORADIC AMOUNT, INCLUDING ANY SCHEDULED PAYMENT AND ANY NSF FEE OR LATE PAYMENT FEE.
    </small>
    <p className="tw-mt-4 tw-mb-1">Recourse/Reimbursement Rights:</p>
    <small>
      The Payor has certain recourse rights if any debit does not comply with this PAD Agreement. For example, the Payor
      has the right to receive reimbursement for any debit that is not authorized or is not consistent with this PAD
      Agreement. To obtain more information on the Payor's recourse rights, the Payor may contact its Processing
      Institution or visit{' '}
      <a href="www.cdnpay.ca" className="tw-text-primary-blue tw-underline" target="_blank" rel="noopener noreferrer">
        www.cdnpay.ca
      </a>
      .
    </small>
    <p className="tw-mt-4 tw-mb-1">Cancellation Rights:</p>
    <small className="tw-mb-2">
      This authority is to remain in effect until the earliest of (a) the termination of the Loop Platform Agreement and
      the indefeasible payment in full of the Obligations under all applicable Loop product Agreements; and (b) the
      cancellation of this PAD Agreement in accordance with the terms contained herein.
    </small>
    <br />
    <small className="tw-mb-2">
      This PAD Agreement may be cancelled at any time provided that notice in writing is received no later than thirty
      (30) days before the next scheduled debit. To obtain a sample cancellation form, or for more information on the
      Payor’s right to cancel this PAD Agreement, contact the Payor’s Processing Institution or visit{' '}
      <a href="www.cdnpay.ca" className="tw-text-primary-blue tw-underline" target="_blank" rel="noopener noreferrer">
        www.cdnpay.ca
      </a>
      .
    </small>
    <br />
    <small className="tw-mb-2">
      Should the Payor elect to cancel this PAD Agreement, the Payor must either enter into another PAD Agreement in
      form and substance satisfactory to Loop or make other arrangements to the satisfaction of Loop for the payments to
      be made under the Loop Platform and applicable Loop product agreements.
    </small>
    <p className="tw-mt-4 tw-mb-1">Jurisdiction:</p>
    <small>
      Any legal action or proceeding arising out of or relating to this Agreement shall be instituted in the courts of
      the Province of Ontario, and the parties hereby submit to the exclusive jurisdiction of such courts.
    </small>
    <p className="tw-mt-4 tw-mb-1">Loop Financial Inc.'s Contact Information</p>
    <Information label="Street Address" value="410 Adelaide St. W, Suite 500" />
    <Information label="City" value="Toronto" />
    <Information label="Province" value="Ontario" />
    <Information label="Postal Code" value="M5V 1S8" />
    <Information label="Email Address" value={config.legalEmailAddress} />
    <Information label="Contact Number" value="1-888-223-5667" />
    <Information label="Fax Number" value="647-490-4668" />
  </>
);

const Information = ({ label, value }: { label: string; value?: string }) => (
  <div className="tw-flex">
    <small className="tw-font-semibold tw-mr-1">{`${label}:`}</small>
    <small>{value}</small>
  </div>
);

export default PADAgreementCA;
