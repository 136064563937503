import React from 'react';
import { DatePicker, MoneyInputField, Select, TextField } from 'components/FormFields/v2';
import { FormProvider } from 'react-hook-form';
import { formatMoneyV2, validateMoneyInput } from 'utility/currency';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import { Currencies } from 'constants/currencies';
import { useAddReimbursementRequestDetails } from './hooks/useAddReimbursementRequestDetails';
import { StepsProps } from '../Steps.types';
import ModalFooter from '../../ModalFooter';
import { ReceiptUpload } from './components';

const AddReimbursementRequestDetails = ({ onNextStep, onFinish }: Pick<StepsProps, 'onNextStep' | 'onFinish'>) => {
  const {
    form,
    handleSubmit,
    handleOnNextStep,
    register,
    formattedAmountRequested,
    setFormattedAmountRequested,
    formattedOriginalAmount,
    setFormattedOriginalAmount,
    originalAmountCurrencyOptions,
    formattedTaxAmount,
    setFormattedTaxAmount,
    showAmountInCAD,
    originalAmountCurrency,
  } = useAddReimbursementRequestDetails({ onNextStep });

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleOnNextStep)}>
          <div className="tw-flex tw-flex-col tw-px-8 tw-py-8 tw-gap-5">
            <div className="tw-text-sm tw-text-neutral-grey-2">
              Enter the details of your reimbursement and upload a receipt.
            </div>
            <TextField
              name="description"
              data-testid="description"
              label="Description"
              placeholder="Dinner with the team"
              ref={register({ required: true })}
              required
            />
            <TextField
              name="merchantName"
              data-testid="merchantName"
              label="Merchant Name"
              placeholder="Uber Eats"
              ref={register({ required: true })}
              required
            />
            <DatePicker
              name="transactionDate"
              rootClass="tw-w-3/4"
              label="Expense Date"
              maxDate={Date.now()}
              placeholder="Select Date"
              required
              ref={register({ required: true })}
            />
            <div className="tw-flex tw-items-start">
              <MoneyInputField
                name="amount"
                label="Amount"
                required
                rootClass="tw-w-3/4"
                currency={originalAmountCurrency}
                moneyFormatter={formatMoneyV2}
                value={formattedOriginalAmount}
                setValue={setFormattedOriginalAmount}
                ref={register({
                  required: true,
                  validate: (value) => validateMoneyInput(value, setFormattedOriginalAmount),
                })}
                data-testid="amount"
              />
              <div className="tw-w-1/4 tw-mb-0 tw-ml-3">
                <Select
                  name="originalAmountCurrency"
                  label="Currency"
                  ref={register()}
                  options={originalAmountCurrencyOptions}
                />
              </div>
            </div>
            <div className="tw-flex tw-items-start">
              <MoneyInputField
                name="taxAmount"
                label="Tax Amount"
                rootClass="tw-w-3/4"
                currency={originalAmountCurrency}
                moneyFormatter={formatMoneyV2}
                value={formattedTaxAmount}
                setValue={setFormattedTaxAmount}
                ref={register({
                  validate: (value) => validateMoneyInput(value, setFormattedTaxAmount, false),
                })}
                data-testid="taxAmount"
              />
              <div className="tw-flex tw-flex-col tw-w-1/4 tw-ml-4 tw-mb-2">
                <div className="tw-text-neutral-grey-1 tw-text-sm tw-mb-2">Currency</div>
                <div className="tw-flex tw-items-center tw-h-11 tw-bg-neutral-grey-4 tw-rounded-md tw-p-4 tw-flex-grow-0">
                  <div className="tw-rounded-full tw-bg-neutral-light tw-mr-1 tw-p-0.5">
                    <MobileCurrencyFlag currency={originalAmountCurrency} size={16} />
                  </div>
                  <p className="tw-text-neutral-grey-2">{originalAmountCurrency}</p>
                </div>
              </div>
            </div>
            {showAmountInCAD && (
              <div className="tw-flex tw-items-start">
                <MoneyInputField
                  name="amountInCAD"
                  label="Amount in CAD"
                  rootClass="tw-w-3/4"
                  required
                  currency={Currencies.CAD}
                  moneyFormatter={formatMoneyV2}
                  value={formattedAmountRequested}
                  setValue={setFormattedAmountRequested}
                  ref={register({
                    required: true,
                    validate: (value) => validateMoneyInput(value, setFormattedAmountRequested),
                  })}
                  data-testid="amountInCAD"
                />
                <div className="tw-flex tw-flex-col tw-w-1/4 tw-ml-4 tw-mb-2">
                  <div className="tw-text-neutral-grey-1 tw-text-sm tw-mb-2">Currency</div>
                  <div className="tw-flex tw-items-center tw-h-11 tw-bg-neutral-grey-4 tw-rounded-md tw-p-4 tw-flex-grow-0">
                    <div className="tw-rounded-full tw-bg-neutral-light tw-mr-1 tw-p-0.5">
                      <MobileCurrencyFlag currency={Currencies.CAD} size={16} />
                    </div>
                    <p className="tw-text-neutral-grey-2">{'CAD'}</p>
                  </div>
                </div>
              </div>
            )}
            <ReceiptUpload
              name="blobSignedId"
              label="Receipt"
              rules={{
                required: true,
              }}
            />
          </div>
          <ModalFooter
            onCancel={onFinish}
            onSubmit={handleSubmit(handleOnNextStep)}
            cancelButtonLabel="Cancel"
            submitButtonLabel="Next"
          />
        </form>
      </FormProvider>
    </>
  );
};

export default AddReimbursementRequestDetails;
