import React, { useState, useEffect, useContext } from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import { Transition } from '@headlessui/react';

import { AuthContext } from 'context/Auth';
import { SettingsContext } from 'context/Settings';
import { TeamContext } from 'components/settings/Team/TeamContext';
import SettingsLayout from 'containers/settings/SettingsLayout';
import { Loaders } from 'components/cards/Loader';
import Button from 'components/Button';
import Close from 'components/svg/Close';
import { Modal, ProgressBar } from 'components/UI';
import MembersTable from 'components/settings/Team/MembersTable';
import Filters from 'components/settings/Team/Filters';
import MemberDetail from 'components/settings/Team/MemberDetail';
import { Steps } from './Steps';

const Team = () => (
  <SettingsLayout>
    <Content />
  </SettingsLayout>
);

const Content = () => {
  const { meLoading } = useContext(AuthContext);
  const { loadingMembers, members } = useContext(SettingsContext);
  const { visibleMembers, ...filterProps } = useMemberFilters(members);
  const [showInviteMemberModal, setShowInviteMemberModal] = useState(false);

  const [selectedContact, setSelectedContact] = useState();

  if (loadingMembers || meLoading) return <Loaders.Light />;

  const onShowInviteMemberModal = () => {
    setSelectedContact(null);
    setShowInviteMemberModal(true);
  };
  const onInviteExistingContact = (contact) => {
    setSelectedContact(contact);
    setShowInviteMemberModal(true);
  };

  return (
    <div className="tw-px-4 tw-py-8">
      <div className="tw-space-y-8 tw-mb-8">
        <div className="tw-flex tw-justify-between">
          <h5>{`Team ${members.length > 0 ? `(${members.length})` : ''}`}</h5>
          <Button primary onClick={onShowInviteMemberModal}>
            Add New User
            <BsPlusCircle size={18} className="tw-text-neutral-light tw-ml-2" />
          </Button>
        </div>
        <Filters {...filterProps} />
        <MembersTable members={visibleMembers} onInviteExistingContact={onInviteExistingContact} />
        <InviteTeamMemberModal
          show={showInviteMemberModal}
          setShow={setShowInviteMemberModal}
          initialContact={selectedContact}
          members={members}
        />
      </div>
      <MemberDetailOverlay members={members} />
    </div>
  );
};

const useMemberFilters = (members) => {
  const [roles, setRoles] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [visibleMembers, setVisibleMembers] = useState(members);

  useEffect(() => {
    let filtered = members;

    if (roles.length > 0) {
      filtered = filtered.filter((member) => roles.includes(member.role));
    }
    if (statuses.length > 0) {
      filtered = filtered.filter((member) => statuses.includes(member.status));
    }

    setVisibleMembers(filtered);
  }, [roles, statuses, members]);

  const onClearFilters = () => {
    setStatuses([]);
    setRoles([]);
  };

  return { visibleMembers, onClearFilters, roles, setRoles, statuses, setStatuses };
};

export const inviteMemberSteps = {
  'User Details': 0,
  'Assign a Role': 1,
  'Define Permissions': 2,
};

const InviteTeamMemberModal = ({ show, setShow, initialContact, members }) => {
  const onClose = () => setShow(false);
  const { refetchMembersRef } = useContext(SettingsContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepTitle, setStepTitle] = useState('User Details');

  const onSuccess = () => {
    refetchMembersRef.current && refetchMembersRef.current();
    onClose();
  };

  useEffect(() => {
    const stepTitle = Object.keys(inviteMemberSteps).find((key) => inviteMemberSteps[key] === currentStep);
    setStepTitle(stepTitle);
  }, [currentStep]);

  return (
    <Modal show={show} onClose={onClose} title={stepTitle}>
      <div className="tw-flex tw-flex-col tw-pt-4 tw-pb-2">
        <ProgressBar
          className="tw-mb-4 tw-px-8"
          currentStepIndex={currentStep}
          stepLabelsAndIndexes={inviteMemberSteps}
        />
        <Steps
          initialContact={initialContact}
          onSuccess={onSuccess}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          allMembers={members}
          onClose={onClose}
        />
      </div>
    </Modal>
  );
};

const MemberDetailOverlay = ({ members }) => {
  const { member, setMember } = useContext(TeamContext);

  const onCloseOverlay = () => setMember(null);

  return (
    <Transition
      show={!!member}
      as="div"
      className="tw-h-screen tw-w-2/5 tw-fixed tw-top-0 tw-right-0 tw-shadow-notification tw-z-10 tw-bg-neutral-light tw-transform  tw-overflow-auto tw-h-full"
      enter="tw-ease-in-out tw-duration-300"
      enterFrom="tw-translate-x-96"
      enterTo="tw-translate-x-0"
      leave="tw-ease-in-out tw-duration-300"
      leaveFrom="tw-translate-x-0"
      leaveTo="tw-translate-x-96"
    >
      <MemberDetail
        member={member}
        header={
          <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
            <small className="tw-text-neutral-grey-2">User details</small>
            <Close className="tw-cursor-pointer" onClick={onCloseOverlay} />
          </div>
        }
        onClose={onCloseOverlay}
        setMember={setMember}
        allMembers={members}
      />
    </Transition>
  );
};

export default Team;
