import React from 'react';
import { useHistory } from 'react-router-dom';

import { Banner } from 'components/UI';
import { BannerType } from 'components/UI/Banner';
import Briefcase from 'components/svg/Briefcase';

const PendingBusinessDetails = () => {
  const history = useHistory();
  const navigateToOnboardingStep = () => history.push('/onboarding/additional-details');

  return (
    <Banner
      type={BannerType.warning}
      title="Verify Your Business"
      message="Upload additional document or re-send links to your directors to verify your business details and gain full access to your dashboard."
      submitButtonLabel="Business Verification"
      onSubmit={navigateToOnboardingStep}
      icon={
        <div className="tw-mx-4">
          <Briefcase />
        </div>
      }
      iconAlignment="center"
      classNames="tw-mt-8 tw-p-3"
    />
  );
};

export default PendingBusinessDetails;
