import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Collapse } from 'react-collapse';
import _ from 'lodash';

import { useGetSubscription } from 'hooks/';
import useIsMobile from 'hooks/useIsMobile';
import useIsMember from 'hooks/useIsMember';
import useScrollViewToPosition from 'hooks/useScrollViewToPosition';
import { NotificationAlertContext } from 'context/NotificationAlert';
import { AuthContext } from 'context/Auth';
import { SettingsContext } from 'context/Settings';
import InitialsCirclesGroup from 'components/InitialsCirclesGroup';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import { validAccountingIntegrations } from 'components/settings/Integrations/constants';
import useFeatureToggle from 'hooks/useFeatureToggle';
import useIsContactPermission from 'hooks/useIsContactPermission';

const SettingsLayout = ({ children }) => {
  const { notification, setNotification } = useContext(NotificationAlertContext);

  useScrollViewToPosition();
  const isMobile = useIsMobile();
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      setNotification(null);
    }, 10000);
  }, [notification]);

  if (isMobile) history.push('/dashboard/home');

  return (
    <DashboardLayout title="Account Settings">
      <div className="tw-flex tw-flex-col">
        <PopupNotification notification={notification} />
        <h2 className="tw-mb-5">Settings</h2>
        <span className="tw-text-sm tw-font-medium tw-pb-10 tw-text-neutral-grey-1">
          Here you can view and update your details and manage your login options and passwords.
        </span>
        <div className="tw-flex tw-flex-col tw-mb-5">
          <SettingsNavigation />
          <hr className="tw-border-neutral-grey-4 tw-mb-3" />
          {children}
        </div>
      </div>
    </DashboardLayout>
  );
};

const SettingsNavigation = () => {
  const {
    loadingMembers,
    loadingIntegrations,
    loadingBankAccounts,
    waitingForBankDetails,
    members,
    externalAccounts,
    bankAccounts,
  } = useContext(SettingsContext);
  const { me, meLoading } = useContext(AuthContext);
  const role = _.get(me, 'internalContact.role');
  const { isMember } = useIsMember();
  const { manageAccountingIntegrations, manageMembers, managePayments, manageExternalAccounts, manageBankAccounts } =
    useIsContactPermission();

  const items = getItems({
    meLoading,
    loadingMembers,
    loadingIntegrations,
    loadingBankAccounts,
    waitingForBankDetails,
    managePayments,
    manageAccountingIntegrations,
    manageBankAccounts,
    manageExternalAccounts,
    manageMembers,
    members,
    externalAccounts,
    bankAccounts,
    isMember,
    role,
  });

  const selectedItem = items.find((item) => item.subRoutes.includes(location.pathname));

  return (
    <div className="tw-flex tw-flex-wrap tw-mb-3 tw-justify-start">
      {items.map((item) => {
        const isSelected = selectedItem && selectedItem.label === item.label;

        return (
          <Link
            key={item.label}
            to={item.route}
            data-testid="settings-navigation-item"
            className={`tw-items-center tw-flex tw-border tw-rounded-md hover:tw-text-primary-dark-green hover:tw-border-secondary-pastel-green-80 hover:tw-bg-secondary-light-green tw-m-2 tw-mr-4 tw-ml-0 tw-p-2 ${
              isSelected
                ? 'tw-bg-secondary-light-green tw-border-secondary-pastel-green-80 tw-text-primary-dark-green'
                : 'tw-border-neutral-grey-4 tw-neutral-grey-1'
            }`}
          >
            <span className="tw-text-sm">{item.label}</span>
            {item.rightElement && <div className="tw-ml-3">{item.rightElement}</div>}
          </Link>
        );
      })}
    </div>
  );
};

const getItems = ({
  meLoading,
  loadingMembers,
  loadingIntegrations,
  loadingBankAccounts,
  waitingForBankDetails,
  manageMembers,
  members,
  externalAccounts,
  bankAccounts,
  managePayments,
  manageAccountingIntegrations,
  manageExternalAccounts,
  manageBankAccounts,
  isMember,
}) => {
  const { isPaidSubscription } = useGetSubscription();
  const { isExperimentEnabled: isEmailReceiptsEnabled } = useFeatureToggle(true, 'email_receipts');
  const items = [
    {
      route: '/dashboard/settings/personal',
      label: 'Personal',
      subRoutes: ['/dashboard/settings/personal', '/dashboard/settings/2fa'],
      hidden: false,
    },
    {
      route: '/dashboard/settings/notifications',
      label: 'Notifications',
      subRoutes: ['/dashboard/settings/notifications'],
      hidden: false,
    },
    {
      route: '/dashboard/settings/expense-management',
      label: 'Expense Management',
      subRoutes: ['/dashboard/settings/expense-management'],
      hidden: !isPaidSubscription,
    },
    {
      route: '/dashboard/settings/integrations',
      label:
        (!meLoading || !loadingIntegrations) && externalAccounts
          ? `Sales Integrations (${
              externalAccounts.filter((ea) => !validAccountingIntegrations().includes(ea.name)).length
            })`
          : 'Sales Integrations',
      subRoutes: ['/dashboard/settings/integrations', '/bank-connections/callback'],
      hidden: !manageExternalAccounts,
    },
    {
      route: '/dashboard/settings/accounting-integrations',
      label: `Accounting Integrations (${
        externalAccounts.filter((ea) => validAccountingIntegrations().includes(ea.name)).length
      })`,
      subRoutes: ['/dashboard/settings/accounting-integrations', '/dashboard/settings/accountingIntegration'],
      hidden: !manageAccountingIntegrations,
    },
    {
      route: '/dashboard/settings/bank-accounts-settings',
      label:
        (!meLoading || !loadingBankAccounts || !waitingForBankDetails) && bankAccounts
          ? `Bank Accounts (${bankAccounts.length})`
          : 'Bank Accounts',
      subRoutes: [
        '/dashboard/settings/bank-accounts-settings',
        '/dashboard/settings/bank-accounts-settings/add',
        '/dashboard/settings/bank-accounts-settings/connect',
        '/bank-accounts/verify',
      ],
      hidden: !manageBankAccounts,
    },
    {
      route: '/dashboard/settings/documents',
      label: 'Documents',
      subRoutes: ['/dashboard/settings/documents'],
      hidden: isMember,
    },
  ];

  const teamItem = {
    route: '/dashboard/settings/team',
    label: (!meLoading || !loadingMembers) && members ? `Team (${members.length})` : 'Team',
    subRoutes: ['/dashboard/settings/team'],
    rightElement: members && (
      <InitialsCirclesGroup isLoading={meLoading || loadingMembers} initialsList={members.map((m) => m.initials)} />
    ),
  };
  const agreementsItem = {
    route: '/dashboard/settings/agreements',
    label: 'Agreements',
    subRoutes: ['/dashboard/settings/agreements'],
  };
  const paymentsItem = {
    route: '/dashboard/settings/payments',
    label: 'Payment Approvals',
    subRoutes: ['/dashboard/settings/payments'],
  };

  if (managePayments) items.push(paymentsItem);
  if (manageMembers) items.push(teamItem);
  if (!isMember) items.push(agreementsItem);

  if (!isEmailReceiptsEnabled) {
    return items
      .filter((item) => item.route !== '/dashboard/settings/expense-management')
      .filter((item) => !item.hidden);
  }

  return items.filter((item) => !item.hidden);
};

export const PopupNotification = ({ notification }) => {
  return (
    <Collapse isOpened={!!notification}>
      <div role={'notification'} className="tw-flex tw-justify-center tw-py-3 tw-mb-8">
        {notification}
      </div>
    </Collapse>
  );
};

export default SettingsLayout;
