import React from 'react';

import { StatBanner } from 'components/UI/Banner';
import { StatutoryHolidays } from './holidays';

export const StatutoryBanner = (): JSX.Element | null => {
  const todayTimeStamp = Date.now();
  const holidaysToShow = StatutoryHolidays.filter(
    (holiday) => todayTimeStamp > holiday.startDateTimestamp && holiday.endDateTimestamp > todayTimeStamp
  );

  if (holidaysToShow.length === 0) return null;

  return (
    <>
      {holidaysToShow.map((holiday) => (
        <StatBanner
          key={holiday.title}
          title={holiday.title}
          content={holiday.message}
          currencies={holiday.currencies}
        />
      ))}
    </>
  );
};
