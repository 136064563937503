export enum CADAgreementDocumentType {
  'rc518_tax_residence_for_individuals' = 'rc518_tax_residence_for_individuals',
  'rc519_tax_residence_for_entities_2plus_directors' = 'rc519_tax_residence_for_entities_2plus_directors',
  'tax_residence_declaration_agreement' = 'tax_residence_declaration_agreement',
}

export enum USDAgreementDocumentType {
  'us_owner_tax_reporting_agreement' = 'us_owner_tax_reporting_agreement',
  'w8ben_tax_reporting_agreement_for_individuals' = 'w8ben_tax_reporting_agreement_for_individuals',
  'w8imy_tax_reporting_agreement_for_partnerships' = 'w8imy_tax_reporting_agreement_for_partnerships',
}

export enum AgreementStatus {
  'draft' = 'draft',
  'pending' = 'pending',
  'sent' = 'sent',
  'signed' = 'signed',
  'declined' = 'declined',
}

export enum Country {
  'CA' = 'CA',
  'US' = 'US',
}

export type CoreExternalAccountAgreement = {
  id: string;
  documentType: CADAgreementDocumentType | USDAgreementDocumentType;
  name: string;
  status: AgreementStatus | null;
  envelopeId: string | null;
  url: string | null;
};
