import React from 'react';
import { RequestDetails } from '../ReviewReimbursementRequestDetails/components';
import { formatDateTime } from 'utility/date';
import { formatMoneyV2 } from 'utility/currency';
import ModalFooter from '../../ModalFooter';
import { CompleteReimbursementRequestDetailsProps } from './CompleteReimbursementRequest.types';
import useCompleteReimbursementRequestDetails from './hooks/useCompleteReimbursementRequest';
import { BiCheck } from 'react-icons/bi';

const CompleteReimbursementRequestDetails = ({ onFinish, onResetSteps }: CompleteReimbursementRequestDetailsProps) => {
  const {
    merchantName,
    description,
    amountRequested,
    originalAmount,
    taxAmount,
    transactionDate,
    showAmountInCAD,
    showTaxAmount,
  } = useCompleteReimbursementRequestDetails();

  return (
    <>
      <div className="tw-px-8 tw-pt-8 tw-mb-8">
        <div className="tw-p-2 tw-flex tw-justify-center tw-items-center tw-rounded-md tw-bg-secondary-light-green tw-mb-8">
          <div className="tw-mr-2 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-semantic-success">
            <BiCheck className="tw-text-secondary-light-green" />
          </div>
          <div className="tw-text-sm tw-text-semantic-success">
            Your Reimbursement Request has been sent for approval.
          </div>
        </div>
        <div className="tw-flex tw-gap-8 tw-py-8 tw-pb-4">
          <div className="tw-flex tw-flex-col tw-space-y-8 tw-w-1/2">
            <RequestDetails label="Description" value={description} />
            <RequestDetails label="Merchant Name" value={merchantName} />
            <RequestDetails label="Expense Date" value={formatDateTime(transactionDate)} />
          </div>
          <div className="tw-flex tw-flex-col tw-space-y-8 tw-w-1/2">
            <RequestDetails
              label="Amount Requested"
              value={`${formatMoneyV2(originalAmount)} ${originalAmount?.currency} `}
            />
            {showAmountInCAD && <RequestDetails label="Amount in CAD" value={`${formatMoneyV2(amountRequested)}`} />}
            {showTaxAmount && (
              <RequestDetails label="Tax Amount" value={`${formatMoneyV2(taxAmount)} ${taxAmount?.currency}`} />
            )}
          </div>
        </div>
      </div>
      <ModalFooter
        onCancel={onFinish}
        cancelButtonLabel="Close"
        onSubmit={onResetSteps}
        submitButtonLabel="Request Another Reimbursement"
      />
    </>
  );
};

export default CompleteReimbursementRequestDetails;
