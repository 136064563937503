import React, { useContext } from 'react';
import { RiMailAddLine, RiDeleteBin2Line } from 'react-icons/ri';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { AuthContext } from 'context/Auth';
import { SettingsContext } from 'context/Settings';
import { TEAM_MEMBER_STATUS, TEAM_INVITATION_STATUS } from 'constants/index';
import { RESEND_TEAM_INVITATION, REVOKE_TEAM_INVITATION } from 'graphql/team';
import Add from 'components/svg/Add';
import InitialsCircle from 'components/InitialsCircle';
import { TeamContext } from './TeamContext';
import MemberStatusBadge from './MemberStatusBadge';
import { Loaders } from 'components/cards/Loader';

const MembersTable = ({ members, onInviteExistingContact }) => {
  const { me } = useContext(AuthContext);
  const { email } = me || {};
  const sortedMembers = members.sort((a, b) => {
    if (a.email === email) return -1;
    if (b.email === email) return 1;
    return 0;
  });

  return (
    <table className="tw-table tw-table-auto tw-w-full">
      <thead>
        <tr>
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-1 tw-font-normal" />
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-1 tw-font-normal">Name</th>
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-1 tw-font-normal">Status</th>
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-1 tw-font-normal">Role</th>
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-1 tw-font-normal">Position</th>
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-1 tw-font-normal">Last Active</th>
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-1 tw-font-normal" />
        </tr>
      </thead>
      <tbody>
        {sortedMembers.map((member) => (
          <MemberRow
            key={member.id}
            member={member}
            meEmail={email}
            onInviteExistingContact={onInviteExistingContact}
          />
        ))}
      </tbody>
    </table>
  );
};

const MemberRow = ({ member, meEmail, onInviteExistingContact }) => {
  const [resendTeamInvitation, { loading: resendTeamInvitationLoading, error: resendTeamInvitationError }] =
    useMutation(RESEND_TEAM_INVITATION);
  const [revokeTeamInvitation, { loading: revokeTeamInvitationLoading, error: revokeTeamInvitationError }] =
    useMutation(REVOKE_TEAM_INVITATION);
  const { setMember } = useContext(TeamContext);
  const { refetchMembersRef } = useContext(SettingsContext);
  const memberIsMe = member.email === meEmail;

  const handleInviteExistingContact = () => {
    onInviteExistingContact({
      firstName: member.firstName,
      lastName: member.lastName,
      email: member.email,
      contactId: member.id,
    });
  };

  const onSelectMember = () => {
    setMember(member);
  };

  const onResendTeamInvitation = async () => {
    try {
      await resendTeamInvitation({
        variables: {
          teamInvitationId: member.id,
        },
      });
      toast.success('Team Invitation resent successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.error(resendTeamInvitationError);
      toast.error('Error sending email - please try again', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onRevokeTeamInvitation = async () => {
    try {
      await revokeTeamInvitation({
        variables: {
          teamInvitationId: member.id,
        },
      });
      toast.success('Team Invitation revoked successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      refetchMembersRef.current && refetchMembersRef.current();
    } catch (err) {
      console.error(revokeTeamInvitationError);
      toast.error('Error revoking invitation - please try again', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <tr className="tw-cursor-pointer hover:tw-shadow-notification tw-transition-all tw-duration-300">
      <td className="tw-border-b tw-border-neutral-grey-5 tw-pl-4" onClick={onSelectMember}>
        <InitialsCircle.Medium initials={member.initials} />
      </td>
      <td className="tw-p-3 tw-border-b tw-border-neutral-grey-5" onClick={onSelectMember}>
        <div className="tw-flex tw-flex-col">
          <strong>{memberIsMe ? `${member.fullName} (you)` : member.fullName}</strong>
          <small className="tw-text-neutral-grey-1">{member.email}</small>
        </div>
      </td>
      <td className="tw-p-3 tw-border-b tw-border-neutral-grey-5" onClick={onSelectMember}>
        <MemberStatusBadge status={member.status} />
      </td>
      <td className="tw-p-3 tw-border-b tw-border-neutral-grey-5" onClick={onSelectMember}>
        <small>{member.role}</small>
      </td>
      <td className="tw-p-3 tw-border-b tw-border-neutral-grey-5" onClick={onSelectMember}>
        <small>{member.positions.length > 0 ? member.positions.join('/') : '-'}</small>
      </td>
      <td className="tw-p-3 tw-border-b tw-border-neutral-grey-5" onClick={onSelectMember}>
        <small>{member.lastActiveAt}</small>
      </td>
      <td className="tw-p-3 tw-border-b tw-border-neutral-grey-5">
        {member.status === TEAM_MEMBER_STATUS.not_invited && (
          <div
            onClick={handleInviteExistingContact}
            className="tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-space-x-2 tw-text-primary-dark-green"
          >
            <small className="tw-font-semibold">Send Invitation</small>
            <Add size={16} />
          </div>
        )}
        {resendTeamInvitationLoading ? (
          <div className="tw-flex tw-justify-center">
            <Loaders.Spinner />
          </div>
        ) : (
          member.status === TEAM_INVITATION_STATUS.pending && (
            <div
              onClick={onResendTeamInvitation}
              className="tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-space-x-2 tw-text-primary-dark-green"
            >
              <small className="tw-font-semibold">Resend Invitation</small>
              <RiMailAddLine size={16} />
            </div>
          )
        )}
      </td>
      <td className="tw-p-3 tw-border-b tw-border-neutral-grey-5">
        {revokeTeamInvitationLoading ? (
          <div className="tw-flex tw-justify-center">
            <Loaders.Spinner />
          </div>
        ) : (
          member.status === TEAM_INVITATION_STATUS.pending && (
            <div
              onClick={onRevokeTeamInvitation}
              className="tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-space-x-2 tw-text-primary-black"
            >
              <small className="tw-font-semibold">Revoke Invitation</small>
              <RiDeleteBin2Line size={16} />
            </div>
          )
        )}
      </td>
    </tr>
  );
};

export default MembersTable;
