import { useContext } from 'react';

import { CreateReimbursementRequestContext } from 'components/Approvals/ApprovalsContent/components/ReimbursementRequestModal/context/CreateReimbursementRequest';

const useCompleteReimbursementRequestDetails = () => {
  const { reimbursementRequestInfo, showAmountInCAD, showTaxAmount } = useContext(CreateReimbursementRequestContext);

  const { amountRequested, originalAmount, taxAmount, merchantName, transactionDate, description, category } =
    reimbursementRequestInfo || {};

  return {
    amountRequested,
    merchantName,
    originalAmount,
    showAmountInCAD,
    showTaxAmount,
    transactionDate,
    defaultApprover: '-',
    description,
    category,
    taxAmount,
  };
};

export default useCompleteReimbursementRequestDetails;
