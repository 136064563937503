import React from 'react';

import { TimedComponentProps } from './TimedComponentWrapper.types';

const TimedComponentWrapper = ({ startAt, endAt, children }: TimedComponentProps): JSX.Element | null => {
  const todayTimeStamp = Date.now();

  const startDate = new Date(startAt).getTime();
  const endDate = new Date(endAt).getTime();

  if (endDate < startDate) return null;

  const shouldDisplay = todayTimeStamp > startDate && endDate > todayTimeStamp;
  if (shouldDisplay) return <>{children}</>;
  return null;
};

export default TimedComponentWrapper;
